import {Modal} from "../../components/modal/Modal";
import styles from "./StakePage.module.css";
import ComingSoon from "@images/desktop/overlay/coming_soon_overlay.png";

interface BuyPageProps {
    show: boolean;
    close: () => void;
}
export const StakePage = ({show, close}: BuyPageProps) => {
    return (
        <Modal show={show} close={close}>
            <div className={styles.cover} style={{
                backgroundImage: `url(${ComingSoon})`,
            }} />

            <div className={styles.content}>
                <div className={styles.title}>Coming <br /> soon</div>
            </div>
        </Modal>
    )
}
