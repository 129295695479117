import React, {useMemo} from "react";
import Fish_Anim_000 from '@images/sequence/fish/Fish_Anim_000.webp';
import Fish_Anim_001 from '@images/sequence/fish/Fish_Anim_001.webp';
import Fish_Anim_002 from '@images/sequence/fish/Fish_Anim_002.webp';
import Fish_Anim_003 from '@images/sequence/fish/Fish_Anim_003.webp';
import Fish_Anim_004 from '@images/sequence/fish/Fish_Anim_004.webp';
import Fish_Anim_005 from '@images/sequence/fish/Fish_Anim_005.webp';
import Fish_Anim_006 from '@images/sequence/fish/Fish_Anim_006.webp';
import Fish_Anim_007 from '@images/sequence/fish/Fish_Anim_007.webp';
import Fish_Anim_008 from '@images/sequence/fish/Fish_Anim_008.webp';
import Fish_Anim_009 from '@images/sequence/fish/Fish_Anim_009.webp';
import Fish_Anim_010 from '@images/sequence/fish/Fish_Anim_010.webp';
import Fish_Anim_011 from '@images/sequence/fish/Fish_Anim_011.webp';
import Fish_Anim_012 from '@images/sequence/fish/Fish_Anim_012.webp';
import Fish_Anim_013 from '@images/sequence/fish/Fish_Anim_013.webp';
import Fish_Anim_014 from '@images/sequence/fish/Fish_Anim_014.webp';
import Fish_Anim_015 from '@images/sequence/fish/Fish_Anim_015.webp';
import Fish_Anim_016 from '@images/sequence/fish/Fish_Anim_016.webp';
import Fish_Anim_017 from '@images/sequence/fish/Fish_Anim_017.webp';
import Fish_Anim_018 from '@images/sequence/fish/Fish_Anim_018.webp';
import Fish_Anim_019 from '@images/sequence/fish/Fish_Anim_019.webp';
import Fish_Anim_020 from '@images/sequence/fish/Fish_Anim_020.webp';
import Fish_Anim_021 from '@images/sequence/fish/Fish_Anim_021.webp';
import Fish_Anim_022 from '@images/sequence/fish/Fish_Anim_022.webp';
import Fish_Anim_023 from '@images/sequence/fish/Fish_Anim_023.webp';
import Fish_Anim_024 from '@images/sequence/fish/Fish_Anim_024.webp';
import Fish_Anim_025 from '@images/sequence/fish/Fish_Anim_025.webp';
import Fish_Anim_026 from '@images/sequence/fish/Fish_Anim_026.webp';
import Fish_Anim_027 from '@images/sequence/fish/Fish_Anim_027.webp';
import Fish_Anim_028 from '@images/sequence/fish/Fish_Anim_028.webp';
import Fish_Anim_029 from '@images/sequence/fish/Fish_Anim_029.webp';
import Fish_Anim_030 from '@images/sequence/fish/Fish_Anim_030.webp';
import Fish_Anim_031 from '@images/sequence/fish/Fish_Anim_031.webp';
import Fish_Anim_032 from '@images/sequence/fish/Fish_Anim_032.webp';
import Fish_Anim_033 from '@images/sequence/fish/Fish_Anim_033.webp';
import Fish_Anim_034 from '@images/sequence/fish/Fish_Anim_034.webp';
import Fish_Anim_035 from '@images/sequence/fish/Fish_Anim_035.webp';
import Fish_Anim_036 from '@images/sequence/fish/Fish_Anim_036.webp';
import Fish_Anim_037 from '@images/sequence/fish/Fish_Anim_037.webp';
import Fish_Anim_038 from '@images/sequence/fish/Fish_Anim_038.webp';
import Fish_Anim_039 from '@images/sequence/fish/Fish_Anim_039.webp';
import Fish_Anim_040 from '@images/sequence/fish/Fish_Anim_040.webp';
import Fish_Anim_041 from '@images/sequence/fish/Fish_Anim_041.webp';
import Fish_Anim_042 from '@images/sequence/fish/Fish_Anim_042.webp';
import Fish_Anim_043 from '@images/sequence/fish/Fish_Anim_043.webp';
import Fish_Anim_044 from '@images/sequence/fish/Fish_Anim_044.webp';
import Fish_Anim_045 from '@images/sequence/fish/Fish_Anim_045.webp';
import Fish_Anim_046 from '@images/sequence/fish/Fish_Anim_046.webp';
import Fish_Anim_047 from '@images/sequence/fish/Fish_Anim_047.webp';
import styles from "./Campfire.module.css";
import {SequenceAnimation} from "../../SequenceAnimation/SequenceAnimation";
import {useImagesLoaded} from "../../service/useImagesLoaded";
interface Props {
    id: string;
    innerRef: any;
    onLoadEnd: () => void;
}

export const Fish = ({id, innerRef, onLoadEnd}: Props) => {
    const images = useMemo(() => {
        return [
        Fish_Anim_000,
        Fish_Anim_001,
        Fish_Anim_002,
        Fish_Anim_003,
        Fish_Anim_004,
        Fish_Anim_005,
        Fish_Anim_006,
        Fish_Anim_007,
        Fish_Anim_008,
        Fish_Anim_009,
        Fish_Anim_010,
        Fish_Anim_011,
        Fish_Anim_012,
        Fish_Anim_013,
        Fish_Anim_014,
        Fish_Anim_015,
        Fish_Anim_016,
        Fish_Anim_017,
        Fish_Anim_018,
        Fish_Anim_019,
        Fish_Anim_020,
        Fish_Anim_021,
        Fish_Anim_022,
        Fish_Anim_023,
        Fish_Anim_024,
        Fish_Anim_025,
        Fish_Anim_026,
        Fish_Anim_027,
        Fish_Anim_028,
        Fish_Anim_029,
        Fish_Anim_030,
        Fish_Anim_031,
        Fish_Anim_032,
        Fish_Anim_033,
        Fish_Anim_034,
        Fish_Anim_035,
        Fish_Anim_036,
        Fish_Anim_037,
        Fish_Anim_038,
        Fish_Anim_039,
        Fish_Anim_040,
        Fish_Anim_041,
        Fish_Anim_042,
        Fish_Anim_043,
        Fish_Anim_044,
        Fish_Anim_045,
        Fish_Anim_046,
        Fish_Anim_047,
    ]}, []);

    useImagesLoaded(images, onLoadEnd);
    return (
        <div id={id} className={styles.container}>
            <SequenceAnimation images={images} ref={innerRef} frameRate={55}/>
        </div>
    )
}
