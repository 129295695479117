export const APP_CONFIG = {
  API: 'https://medystacja-backend.herokuapp.com',
  GAME_HOST: 'https://t.me/Doginhood_bot',
  DOCS_HOST: 'https://doginhood.gitbook.io/dogin-hood',
  TWITTER_HOST: 'https://x.com/doginhood_io',
  DISCORD_HOST: 'https://discord.gg/doginhood',
  // API: 'http://localhost:4000',
  // STRIPE_REDIRECT: 'http://localhost:3000/',
  STRIPE_REDIRECT: 'https://www.zencast.app/',
}
