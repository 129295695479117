import React, {useEffect, useState} from "react";
import styles from "./Modal.module.css";
import BackgroundImage from "@images/mobile/modal_background.png";
import BackArrow from "@images/mobile/back_arrow_green.png";

interface ModalProps {
    show: boolean;
    children: any;
    close: () => void;
}
export function Modal({show, children, close}: ModalProps) {
    const [zoomedWidth, setZoomedWidth] = useState(0);

    useEffect(() => {
        const setDimensions = () => {
            setZoomedWidth(window.innerWidth * 1.1 * 0.95 - 318);
        }
        setDimensions();
        window.addEventListener('load', setDimensions);
        window.addEventListener('resize', setDimensions);


        return () => {
            window.removeEventListener('load', setDimensions);
            window.removeEventListener('resize', setDimensions);
        }
    }, [])


    return (
        <div className={`${styles.background} ${show ? styles.active : ''}`} style={{
            backgroundImage: `url(${BackgroundImage})`,
        }}>
            <div className={styles.content} style={{
                width: `${show ? zoomedWidth : 0}px`,
            }}>
                <button className={`${styles.closeButton} ${show ? styles.closeButtonActive: ''}`} style={{
                    backgroundImage: `url(${BackArrow})`,
                }} onClick={() => {
                    close();
                }}/>

                <div className={`${styles.entranceAnimation} ${show ? styles.entranceAnimationActive: ''}`}> {children}</div>
            </div>
        </div>
    );
}
