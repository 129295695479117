export default {
  LandingPage: {
    hero: {
      title: 'Experience Zen\n' + 'every day.',
      desc: 'Zencast is a free Polish meditation app for mindfulness, breathwork,\n' + 'and personal development.',
    },
    descriptionSection: {
      feeling: {
        title: 'Feel better\n' + 'right away',
        desc: 'with personalized meditations and breath exercises at your fingertips.',
      },
      sleep: {
        title: 'Sleep healthier and deeper',
        desc: 'by doing relaxation exercises before bedtime and falling asleep to the soothing sounds of the sea or forest.',
      },
      teach: {
        title: 'Learn and grow',
        desc: 'with 7-day courses on topics like motivation, stress, and inner peace.',
      },
      people: {
        title: 'Multi-day courses\n' + 'for a better life',
        desc: 'Our mission is to positively impact the well-being of as many people as possible.',
        desc2:
          'We are supported by experts who share their knowledge and tasks\n' +
          'in the form of Zencasts - courses that will help you master the art of motivation, building mental resilience, concentration\n' +
          'in any situation, and much more.',
      },
      category: {
        title: 'Stressed? Lack of motivation? Sleep problems?',
        desc:
          'Our meditations and exercises address the needs of everyday life and work. Whether you feel excessively stressed, \n' +
          'are looking for proven ways to build good habits,\n' +
          'or have simply been annoyed by a client - we have dozens of exercises\n' +
          'and meditations for you.\n' +
          'And we keep adding more.',
      },
      why: {
        title: 'Why should you choose Zencast?',
        desc:
          'Our goal is to create the largest Polish-language database of meditations and development exercises\n' +
          'with the best instructors, trainers,\n' +
          'and holistic approach experts.\n',
        desc2:
          "You'll also receive Zencasts, which are developmental courses on various topics: Relaxing Breath, Healthy Sleep, Work Efficiency, exercises for Parents with Children, and many others leading to well-being. \n",
        desc3: 'All our meditations\n' + 'and exercises are illustrated\n' + 'with original graphics.',
      },
      tryAndDownload: {
        title: 'Want to try?',
        desc: 'Download the app now.',
      },
      body_emotion: {
        title: 'Zencast is your path to harmony\n' + 'of Mind, Body, and Emotions.',
        desc:
          'Regain tranquility, \n' +
          'feel more joy, \n' +
          'and breathe in the fullness of life. \n\n' +
          'Discover how consistency \n' +
          'and awareness will bring \n' +
          'you balance and well-being. \n',
      },
      power: {
        title: 'Discover the power of meditation, breath, and Zencasts',
        left1:
          'Meditation is not just a spiritual practice but also a tool for achieving peace of mind, improving concentration, \n' +
          'reducing stress, and increasing self-awareness.',
        left2:
          'Meditation can lead to a deeper understanding of yourself\n' +
          'and the world around you, positively impacting both mental and physical health.',
        right1:
          "How to start meditating? You don't need special equipment, experience, or extraordinary skills. Our Zencast app is all you need.\n",
        right2:
          'Introduce positive habits with Zencasts and see how a few minutes of focus every day can pave the way to finding the desired balance in the hustle and bustle of everyday life.',
      },
      audioIntroduction: {
        title: 'Start your journey\n' + 'with Zencast.',
        pickUp: 'Take care of yourself now and choose a short exercise',
        loading: 'Loading...',
        click: 'click the selected exercise above\n' + 'and start recording',
      },
      rating: {
        title: 'Zencast started as Medystacja.',
        title2: 'Even then, we were well-received.',
      },
    },
    PeopleStory: {
      title: 'Our Team',
    },
    Testimonials: {
      title: 'Our Satisfied Customers',
    },
    Faq: {
      title: 'Frequently Asked Questions',
    },
    Team: {
      konrad_hopek: {
        desc: 'An engineer with experience in developing software, both mobile and web applications. Computer science studies and years of practice have resulted in several successful startups in the IT field. His main passion is technology and programming, but also the well-being area. Inspired by the benefits of meditation, he created Medystacje and now Zencast - an app that combines relaxation and intellectual development. Zencast promotes mental health in various languages.',
        title: 'CEO and CTO, Chief Founder and App Developer',
      },
      ewa_kruk: {
        desc: "A people-oriented enthusiast with 20 years of experience in personal and professional development, navigating through change. Specializes in optimizing potential, building mental resilience, coping with difficult situations, creating constructive relationships, and the art of motivation. She has conducted tens of thousands of hours of training and workshops on soft skills, as well as individual development and therapeutic consultations. She constantly supervises her work. Collaborates with higher education institutions, creates educational and development programs. Her professional motto is F. Tyger's words: 'Doing what you love is freedom, loving what you do is happiness.'",
        title: 'Personal and Professional Development Specialist, TSR Therapist',
      },
      wojtek: {
        desc: "Breath trainer, networker, Business Development specialist. Has visited 60 countries around the world. Author of the book 'Meetings in Asia'. Worked in the IT industry for over 10 years (Czech Republic, Thailand, Poland). Lived in Asia for 5 years. Currently conducts webinars and workshops for companies, combining the world of business with mental health. Also advises Digital Health startups.",
        title: 'Breath Trainer, Globetrotter, Digital Health Startup Consultant',
      },
      piotr_karwala: {
        desc: "Breath trainer, networker, Business Development specialist. Has visited 60 countries around the world. Author of the book 'Meetings in Asia'. Worked in the IT industry for over 10 years (Czech Republic, Thailand, Poland). Lived in Asia for 5 years. Currently conducts webinars and workshops for companies, combining the world of business with mental health. Also advises Digital Health startups.",
        title: 'Breath Trainer, Globetrotter, Digital Health Startup Consultant',
      },
    },
    lectors: {
      loading: 'Loading Zencasters, please wait...',
      title: "Our Zencasters, the application's instructors:",
    },
    footer: {
      statue: 'Terms of service',
      terms: 'Privacy policy',
    },
  },
  rating: {
    out: 'out of',
  },
  HomePage: {
    title: 'Home dashboard',
    menuList: {
      createStoryTitle: 'Create story',
      createStoryDesc: 'Create a new story for your users.',

      showStoriesTitle: 'Show stories',
      showStoriesDesc: 'Show your created stories and check analytics.',

      personalDateTitle: 'Your personal data',
      personalDateDesc: 'Edit your personal data.',
    },
  },
  example: {
    helloUser: 'I am a fake user, my name is {{name}}',
  },

  menu: {
    login: 'login',
    home: 'home',
    dashboard: 'dashboard',
    stories: 'stories',
    shop: 'Shop',
    logout: 'logout',
  },
  language: {
    title: 'Lang',
    pl: 'polish',
    en: 'english',
  },
  stories: {
    title: 'Title',
    type: 'Type',
    photo: 'Photo',
    description: 'Description',
    language: 'Language',
    createdAt: 'Created At',
    isFree: 'Is for Free',
    hashtags: 'Hashtags',
    availableDate: 'Available Date',
    disable: 'Disable',
  },
  storyPage: {
    title: 'Stories',
    modalVisibilityTitle: 'Select column visibility',
  },

  createStoryPage: {
    mainTitle: 'Create a story for meditations',
    title: 'Title',
    titlePlaceholder: 'Input title for story...',
    desc: 'Description',
    descPlaceholder: 'Input description for story...',
    type: {
      title: 'Story type',
      placeholder: 'Select story type...',
      SINGLE: 'Single meditation',
      DAILY_SINGLE: 'Daily meditation',
      SERIES_DAY_BY_DAY: 'Story with series of meditation',
      SERIES_SAME_TOPIC: 'Story with the same topic',
      SERIES_TIME_LAPSE: 'Story with different time',
    },
    language: 'Language',
    languagePlaceholder: 'Select language for story...',
    isFree: 'Available without premium: for free',
    availableDate: 'Available Date ',
    hashtags: 'Hashtags',
    photo: 'Background Photo of story',
    meditationsTitle: 'Create meditations',
    createMeditation: {
      title: 'Meditation number: {{sequence}}',
      audio: 'Load audio',
      delete: 'Delte',
      add: 'Add new meditation',
    },
    reset: 'Reset all fields',
    save: 'Save story',
    error: {
      title: 'Please input title',
      desc: 'Please input description',
      type: 'Please input type',
      language: 'Please input language',
      hashtags: 'Please input at least one hashtags',
      photo: 'Please input photo',
      titleMeditation: 'Please input meditation title',
      audio: 'Please input medtiation audio',
    },
  },
  modal: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  upload: {
    info: "Drag 'n' drop some files here, or click to select files",
    name: 'File name: ',
    duration: 'Duration: ',
    wrongType:
      'Wrong type of file, please ensure that file which you want to upload is in correct type, if yest please contact administrator',
  },
  storyType: {
    SINGLE: 'single',
    DAILY_SINGLE: 'daily single',
    SERIES_DAY_BY_DAY: 'series day by day',
    SERIES_SAME_TOPIC: 'series with the same topic',
    SERIES_TIME_LAPSE: 'series with time laps',
  },
  hashtags: {
    all: 'all',
    sleep: 'sleep',
    stress: 'stress',
    focus: 'focus',
    anxiety: 'anxiety',
    beginers: 'beginners',
    'self-care': 'self care',
    relax: 'Relax',
    work: 'Work',
    mother: 'for mums',
    development: 'Development',
    health: 'Health',
    relationship: 'Relationship',
    happiness: 'Happiness',
    kids: 'For kids',
    program: 'Program',
  },
  Calendar: {
    days: {
      day0: 'Sun',
      day1: 'Mon',
      day2: 'Tue',
      day3: 'Wed',
      day4: 'Thu',
      day5: 'Fri',
      day6: 'Sat',
    },
    daysLong: {
      day0: 'Sunday',
      day1: 'Monday',
      day2: 'Tuesday',
      day3: 'Wednesday',
      day4: 'Thursday',
      day5: 'Friday',
      day6: 'Saturday',
    },
    month: {
      January: 'Jan',
      February: 'Feb',
      March: 'Mar',
      April: 'Apr',
      May: 'May',
      June: 'Jun',
      July: 'Jul',
      August: 'Aug',
      September: 'Sep',
      October: 'Oct',
      November: 'Nov',
      December: 'Dec',
    },
    monthLong: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
    },
  },
  TimePicker: {
    save: 'SAVE',
    cancel: 'CANCEL',
  },
  LanguageSelector: {
    title: 'Select your language',
  },
}
