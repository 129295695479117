import React, { useState } from 'react'
import styles from './Menu.module.css'
import Banner from '@images/mobile/Banner.png'
import ArrowDownIcon from '@images/mobile/arrow_down.png'
import ArrowUpIcon from '@images/mobile/burger.png'
import { MenuItems } from '../../../../shared/menu.const'
import { APP_CONFIG } from '../../../../../../../config'
import LaunchAppButtonInActiveBg from '@images/launchAppButton/inactive.png'
import { Button } from 'semantic-ui-react'

interface MenuProps {
  show: MenuItems | undefined
  onChange: (value: MenuItems) => void
}

export function Menu({ show, onChange }: MenuProps) {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <div className={`${styles.container} ${showMenu ? styles.containerActive : ''}`}>
      <div
        className={`${styles.banner}`}
        style={{
          backgroundImage: `url(${Banner})`,
        }}
      />

      <div
        className={`${styles.menuItems} ${showMenu ? styles.menuItemsActive : ''} ${
          show ? styles.menuItemsDisactive : ''
        }`}
      >
        <button className={styles.menuItem} onClick={() => (window.location.href = APP_CONFIG.GAME_HOST)}>
          {MenuItems.PLAY}
        </button>
        <button className={styles.menuItem} onClick={() => onChange(MenuItems.BUY)}>
          {MenuItems.BUY}
        </button>
        <button className={styles.menuItem} onClick={() => onChange(MenuItems.STAKE)}>
          {MenuItems.STAKE}
        </button>
        {/*<button className={styles.menuItem}*/}
        {/*        onClick={() => onChange(MenuItems.LAUNCHPAD)}>{MenuItems.LAUNCHPAD}</button>*/}
        <button className={styles.menuItem} onClick={() => (window.location.href = APP_CONFIG.DOCS_HOST)}>
          {MenuItems.DOCS}
        </button>
        <button className={styles.menuItem} onClick={() => onChange(MenuItems.LOTTERY)}>
          {MenuItems.LOTTERY}
        </button>
        <button className={styles.menuItem} onClick={() => onChange(MenuItems.LEADERBOARD)}>
          {MenuItems.LEADERBOARD}
        </button>
        <div>
          <Button
            circular
            color="twitter"
            icon="twitter"
            onClick={() => (window.location.href = APP_CONFIG.TWITTER_HOST)}
          />
          <Button
            circular
            color="purple"
            icon="discord"
            onClick={() => (window.location.href = APP_CONFIG.DISCORD_HOST)}
          />
        </div>
      </div>

      <div className={styles.menuIconContainer}>
        <div
          className={`${styles.menuIconContent} ${showMenu ? styles.menuContentActive : ''}`}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div
            className={`${styles.menuIcon} ${showMenu ? styles.ArrowDownIcon : ''}`}
            style={{
              backgroundImage: `url(${showMenu ? ArrowDownIcon : ArrowUpIcon})`,
            }}
          />
        </div>
        <div className={styles.joinListMenu} onClick={() => onChange(MenuItems.LEADERBOARD)}>
          <span className={styles.joinListText}>LAUNCH APP</span>
        </div>
      </div>
    </div>
  )
}
