import React, {useEffect, useState} from 'react'
import ParallaxDog from "./components/ParallaxDog";
import {CamfireLoader} from "./components/animations/CamfireLoader";
import styled from "styled-components";
import DesktopContainer from "./components/DesktopContainer";
import MobileContainer from "./components/MobileContainer";

const Dimmer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #03422F;
`

function Loading({onLoadEnd}: { onLoadEnd: () => void }) {
    return (
        <Dimmer>
            <CamfireLoader onLoadEnd={onLoadEnd}/>
        </Dimmer>
    )
}

export const LandingPage = () => {
    const [loading, setLoading] = useState(true);
    const [loadedPleolader, setLoadedPleolader] = useState(false)
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const setDimensions = () => {
            setIsMobile(window.innerWidth < 768);
        }
        setDimensions();
        window.addEventListener('load', setDimensions);
        window.addEventListener('resize', setDimensions);


        return () => {
            window.removeEventListener('load', setDimensions);
            window.removeEventListener('resize', setDimensions);
        }
    }, [])

    return (
        <div>
            {loading && <Loading onLoadEnd={() => setLoadedPleolader(true)}/>}
            {loadedPleolader &&
                (<div style={{display: loading ? 'none' : 'block'}}>
                    {isMobile ? <MobileContainer /> : <DesktopContainer/>}
                    <ParallaxDog loading={loading} onLoadEnd={() => setLoading(false)}/>
                </div>)}
        </div>
    )
}
