import {Modal} from "../../components/modal/Modal";
import styles from "./Launch.module.css";
import FishOverlay from "@images/desktop/overlay/fisherman_overlay.png";


interface BuyPageProps {
    show: boolean;
    close: () => void;
}
export const LaunchPage = ({show, close}: BuyPageProps) => {
    return (
        <Modal show={show} close={close}>
            <div className={styles.cover} style={{
                backgroundImage: `url(${FishOverlay})`,
            }} />

            <div className={styles.content}>
                <div className={styles.title}>Launchpad</div>
                <div className={styles.subtitle}>The key to catching a fish lies in sitting and waiting patiently for hours. However, to catch the best deal, it's a different game altogether. Here, you must sweat and get your hands dirty. Grind hard,
                    collaborate and dominate the field, the golden fish awaits you.</div>
            </div>
        </Modal>
    )
}
