import React, {useEffect, useState} from "react";
import styles from './PlayPage.module.css';
import CloseImage from "@images/desktop/close-white.png";
import ChestBonesImage from "@images/desktop/play/chest_bones.png";
import WalkingImage from "@images/desktop/play/walking.png";
import CaveRunImage from "@images/desktop/play/cave_run.png";

import {Frame} from "../../components/frame/Frame";

interface BuyPageProps {
    show: boolean;
    close: () => void;
}
export const PlayPage = ({show, close}: BuyPageProps) => {
    const [zoomedWidth, setZoomedWidth] = useState(0);

    useEffect(() => {
        const setDimensions = () => {
            setZoomedWidth(window.innerWidth);
        }
        setDimensions();
        window.addEventListener('load', setDimensions);
        window.addEventListener('resize', setDimensions);


        return () => {
            window.removeEventListener('load', setDimensions);
            window.removeEventListener('resize', setDimensions);
        }
    }, [])

        return (
            <div className={`${styles.container} ${show ? styles.containerActive: ''}`} style={{pointerEvents: show? 'auto': 'none'}}>
                <button className={`${styles.closeButton} ${show ? styles.closeButtonActive: ''}`} style={{
                    backgroundImage: `url(${CloseImage})`,
                    right: `${zoomedWidth * 0.05 + 338}px`,
                }} onClick={() => {
                    close();
                }}/>

                <div className={styles.frameContainer} style={{width: `${zoomedWidth * 0.95 - 338}px`}}>
                    <Frame>
                        <div className={styles.frameContent}>
                            <div className={styles.frameDescription} style={{
                                backgroundImage: `url(${ChestBonesImage})`,
                                height: `144px`,
                                marginTop: `64px`
                            }} />
                            <div className={styles.frameButton}>Play</div>
                        </div>
                    </Frame>
                    <Frame>
                        <div className={styles.frameContent}>
                            <div className={styles.frameDescription} style={{
                                backgroundImage: `url(${WalkingImage})`,
                                height: `144px`,
                                marginTop: `89px`
                            }} />
                            <div className={styles.frameButton}>Play</div>
                        </div>


                    </Frame>
                    <Frame>
                        <div className={styles.frameContent}>
                            <div className={styles.frameDescription} style={{
                                backgroundImage: `url(${CaveRunImage})`,
                                height: `144px`,
                                marginTop: `103px`
                            }} />
                            <div className={styles.frameButton}>Play</div>
                        </div>

                    </Frame>
                </div>

            </div>
        )

}
