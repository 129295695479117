import {Modal} from "../../components/modal/Modal";
import styles from "./BuyPage.module.css";
import BuyOverlay from "@images/desktop/overlay/buy_overlay.png";
import {useEffect, useState} from "react";

interface BuyPageProps {
    show: boolean;
    close: () => void;
}
export const BuyPage = ({show, close}: BuyPageProps) => {
    const [zoomedWidth, setZoomedWidth] = useState(0);

    useEffect(() => {
        const setDimensions = () => {
            setZoomedWidth(window.innerWidth * 1.1 * 0.95 - 318);
        }
        setDimensions();
        window.addEventListener('load', setDimensions);
        window.addEventListener('resize', setDimensions);


        return () => {
            window.removeEventListener('load', setDimensions);
            window.removeEventListener('resize', setDimensions);
        }
    }, [])

    return (
        <Modal show={show} close={close}>
            <div className={styles.cover} style={{
                backgroundImage: `url(${BuyOverlay})`,
                width: `${zoomedWidth}px`,
                height: `${756* zoomedWidth / 2094}px`,
            }} />

            <div className={styles.content}>
                <div className={styles.title}>BUY</div>
                <div className={styles.subtitle}>I've searched through all the Seven Kingdoms, but no one is selling <span className={styles.italic} >$DOGIN</span>! <br />
                    However, there are rumors that a  gigantic ship full of chests with<span className={styles.italic} > $DOGINs</span> will soon enter the harbor. <br />
                    But... these are just rumors, I'd better keep searching for a stealth offer just for you, my lord!</div>
            </div>
        </Modal>
    )
}
