import styles from "./LaunchPage.module.css";
import FisherManOverlay from "@images/desktop/overlay/fisherman_overlay.png";
import {useEffect, useState} from "react";
import {ModalRight} from "../../components/modal-right/ModalRight";
interface LaunchPageProps {
    show: boolean;
    close: () => void;
}
export const LaunchPage = ({show, close}: LaunchPageProps) => {
    const [zoomedWidth, setZoomedWidth] = useState(0);

    useEffect(() => {
        const setDimensions = () => {
            setZoomedWidth(window.innerWidth * 1.1 * 0.95 - 318);
        }
        setDimensions();
        window.addEventListener('load', setDimensions);
        window.addEventListener('resize', setDimensions);


        return () => {
            window.removeEventListener('load', setDimensions);
            window.removeEventListener('resize', setDimensions);
        }
    }, [])

    return (
        <ModalRight show={show} close={close}>
            <div className={styles.cover} style={{
                backgroundImage: `url(${FisherManOverlay})`,
                width: `${zoomedWidth}px`,
                height: `${914* zoomedWidth / 1921}px`,
            }} />

            <div className={styles.content}>
                <div className={styles.title}>Launchpad</div>
                <div className={styles.subtitle}>The key to catching a fish lies in sitting and waiting patiently for hours. However, to catch the best deal, it's a different game altogether. Here, you must sweat and get your hands dirty. Grind hard,
                    collaborate and dominate the field, the golden fish awaits you.</div>
            </div>
        </ModalRight>
    )
}
