export default {
  LandingPage: {
    hero: {
      title: 'Poczuj Zen\n' + 'każdego dnia.',
      desc: 'Zencast to darmowa polska aplikacja do medytacji, pracy\n' + 'z oddechem i rozwoju osobistego.',
    },
    descriptionSection: {
      feeling: {
        title: 'Od razu \n poczuj się lepiej',
        desc: 'dzięki spersonalizowanym medytacjom i ćwiczeniom oddechowym dostępnym na wyciągnięcie ręki.',
      },
      sleep: {
        title: 'Śpij zdrowiej \n i głębiej',
        desc: 'wykonując ćwiczenia relaksacyjne przed snem  i zasypiając \n  przy relaksującym dźwięku morza czy lasu. ',
      },
      teach: {
        title: 'Ucz się i rozwijaj',
        desc: 'Dzięki różnorodnym kursom o motywacji, stresie i zarządzaniu własną energią!',
      },
      people: {
        title: 'Kilkudniowe kursy\n' + 'dobrego życia ',
        desc: 'Naszą misją jest pozytywny wpływ na dobrostan jak największej liczby osób.',
        desc2:
          'Pomagają nam w tym eksperci dzielący się wiedzą i zadaniami\n' +
          'w formie Zencastów - kursów, dzięki którym opanujesz sztukę motywacji, rozwijania odporności psychicznej, koncentracji' +
          'w każdych warunkach i wiele więcej.',
      },
      category: {
        title: 'Stres? Brak motywacji? Problemy ze snem?',
        desc:
          'Nasze medytacje i ćwiczenia odpowiadają na potrzeby codziennego życia i pracy. Niezależnie czy czujesz się nadmiernie zestresowany, ' +
          'szukasz sprawdzonych sposobów budowania dobrych nawyków' +
          'czy po prostu zdenerwował cię klient - mamy dla Ciebie dziesiątki ćwiczeń' +
          'i medytacji.' +
          'I cały czas dodajemy kolejne.',
      },
      why: {
        title: 'Dlaczego powinieneś wybrać właśnie Zencast?',
        desc:
          'Naszym celem jest stworzenie największej polskojęzycznej bazy medytacji i ćwiczeń rozwojowych\n' +
          'z najlepszymi instruktorami, trenerów\n' +
          'i ekspertów w podejściu holistycznym.\n',
        desc2:
          'Otrzymasz również Zencasty, czyli kursy rozwojowe na różnorodne tematy: Relaksujący oddech, Zdrowy sen, Efektywność w pracy, ćwiczenia Dla rodziców z dziećmi i wiele innych prowadzących do poczucia dobrostanu. \n',
        desc3: 'Wszystkie nasze medytacje\n' + 'i ćwiczenia są ilustrowane\n' + 'oryginalnymi grafikami.',
      },
      tryAndDownload: {
        title: 'Chcesz spróbować?',
        desc: 'Pobierz aplikację już teraz.',
      },
      body_emotion: {
        title: 'Zencast to Twoja ścieżka do harmonii\n' + 'Umysłu, Ciała i Emocji.',
        desc:
          'Odzyskaj spokój, \n' +
          'poczuj więcej radości \n' +
          'i oddychaj pełnią życia. \n\n' +
          'Odkryj, jak regularność \n' +
          'i świadomość przyniosą \n' +
          'Ci równowagę i dobrostan. \n',
      },
      power: {
        title: 'Odkryj moc medytacji, oddechu i Zencastów',
        left1:
          'Medytacja to nie tylko praktyka duchowa, ale także narzędzie do osiągnięcia spokoju umysłu, poprawy koncentracji, ' +
          'redukcji stresu i zwiększenia samoświadomości.',
        left2:
          'Medytacja może prowadzić do głębszego zrozumienia siebie' +
          'i świata wokół ciebie, wpływając pozytywnie na zdrowie zarówno psychiczne, jak i fizyczne.',
        right1:
          'Jak zacząć medytować? Nie potrzeba specjalnego sprzętu, doświadczenia czy wyjątkowych umiejętności. Wystarczy nasza aplikacja Zencast.',
        right2:
          'Wprowadź z nami pozytywne nawyki z Zencastów i zobacz jak kilka minut skupienia każdego dnia może otworzyć drogę do znalezienia upragnionego balansu w zgiełku codziennego życia.',
      },
      audioIntroduction: {
        title: 'Zatrzymaj się w chwili',
        pickUp: 'Zadbaj o siebie już teraz i wybierz krótkie ćwiczenie',
        loading: 'Ładowanie...',
        click: 'kliknij wybrane ćwiczenie powyżej\n' + 'i odsłuchaj nagranie',
      },
      rating: {
        title: 'Zencast zaczynało jako Medystacja.',
        title2: 'Już wtedy dobrze nas oceniano.',
      },
    },
    PeopleStory: {
      title: 'Nasz zespół',
    },
    Testimonials: {
      title: 'Dlaczego warto? \n Sprawdź opinie zadowolonych użytkowników',
    },
    Faq: {
      title: 'Często zadawane pytania',
    },
    Team: {
      konrad_hopek: {
        desc: 'Inżynier z doświadczeniem w tworzeniu oprogramowania, zarówno aplikacji mobilnych i webowych. Studia informatyczne i wieloletnia praktyka zaowocowały kilkoma udanymi startupami w dziedzinie IT. Główna pasją jest technologia i programowanie, ale także obszar well-being. Zainspirowany korzyściami medytacji, stworzył pierw Medystacje a teraz Zencast - aplikację, która łączy relaksację i rozwijanie się intelektualne. Zencast promuje zdrowie psychiczne w różnych językach.',
        title: 'CEO i CTO, główny założyciel oraz programista aplikacji',
      },

      ewa_kruk: {
        desc: 'Pasjonatka pracy z ludźmi. Posiada 20 lat doświadczenia w pracy nad rozwojem osobistym i zawodowym, przechodzeniu przez zmianę. Specjalizuje się w optymalnym wykorzystaniu potencjału, budowaniu odporności psychicznej, radzeniu sobie w sytuacjach trudnych, tworzeniu konstruktywnych relacji i sztuce motywowania. Przeprowadziła kilkanaście tysięcy godzin szkoleń i warsztatów w zakresie kompetencji miękkich oraz konsultacji i sesji indywidualnych rozwojowych i terapeutycznych. Stale superwizuje swoją pracę. Współpracuje z uczelniami wyższymi, tworzy programy edukacyjne i rozwojowe. Motto zawodowe to słowa F. Tygera: "Robienie tego co lubisz jest wolnością, lubienie tego, co robisz jest szczęściem".',
        title: 'Specjalista rozwoju osobistego i zawodowego, terapeuta TSR',
      },
      wojtek: {
        desc: 'Trener oddechu, networker, specjalista Business Development. Odwiedził 60 krajów świata. Autor książki "Spotkania w Azji". Przepracował ponad 10 lat w branży IT (Czechy, Tajlandia, Polska). Mieszkał 5 lat w Azji. Obecnie prowadzi webinary i warsztaty dla firm łącząc świat biznesu ze światem zdrowia mentalnego. Doradza również starup-upom Digital Health.',
        title: 'Trener oddechu, obieżyświat, konsultant startów Digital Health',
      },
      piotr_karwala: {
        desc: 'Trener oddechu, networker, specjalista Business Development. Odwiedził 60 krajów świata. Autor książki "Spotkania w Azji". Przepracował ponad 10 lat w branży IT (Czechy, Tajlandia, Polska). Mieszkał 5 lat w Azji. Obecnie prowadzi webinary i warsztaty dla firm łącząc świat biznesu ze światem zdrowia mentalnego. Doradza również starup-upom Digital Health.',
        title: 'Trener oddechu, obieżyświat, konsultant startów Digital Health',
      },
    },
    lectors: {
      loading: 'Ładowanie zencasterów, proszę czekać...',
      title: 'Nasi Zencasterzy czyli lektorzy aplikacji:',
    },
    footer: {
      statue: 'Regulamin',
      terms: 'Polityka Prywatności',
      aboutUs: 'O nas',
    },
  },
  Statue: {
    title: 'Regulamin aplikacji Zencast',
  },
  Privacy: {
    title: 'Polityka Prywatności Aplikacji Zencast',
  },
  AboutUs: {
    title: 'O nas',
  },
  VoucherShop: {
    title: 'Kup voucher premium do aplikacji Zencast',
    vouchers: {
      loading: `Ładowanie voucher'ów, proszę czekać...`,
      mostPopular: 'Popularny',
      family: 'Pakiet rodzina',
      buyNow: 'Kup teraz',
      quantity: {
        cart: 'Koszyk',
        product: 'Produkt',
        price: 'Cena',
        quantity: 'Ilość',
        subtotal: 'Podsuma',
        shipping: 'Dostawa',
        Pshipping: 'Dostawa',
        total: 'Kwota całkowita',
        goToCheckout: 'Kontynuuj do płatności',
      },
      shopForm: {
        finish: 'Dokończ swoją płatność',
        userName: 'Imię i nazwisko',
        userNamePlaceholder: 'Imię Nazwisko',
        code: 'Prefiks do kodu vouchera',
        codePlaceholder: 'np. nazwa firmy',
        goToPayment: 'Przejdź do płatności',
        loading: 'Proszę czekać...',
        motto: 'Przeżyj coś niesamowitego z aplikacją Zencast...',
        emailError: 'Niepoprawny e-mail, prosimy sprawdź jego poprawność',
      },
    },
  },
  ShopPayment: {
    title: 'Strona płatności',
    pay: 'Wykonaj płatność',
  },
  ShopPaymentReturnPage: {
    title: 'Podsumowanie płatności',
    succeeded:
      'Twoja płatność została zaksiegowana, na podany e-mail przyjdzie Voucher do 24h, jeżeli po tym czasie się nie pojawi, prosimy skontaktować się z nami na adres: kontak@zencast.app',
    failed: 'Niestety twoja płatność się nie udała, prosimy spróbować ponownie',
  },
  rating: {
    out: 'z',
  },
  cookie: {
    text: 'Ta strona korzysta z plików cookie w celu poprawy jakości Twojego doświadczenia podczas przeglądania. Pliki cookie to małe pliki tekstowe przechowywane na Twoim urządzeniu, które pomagają nam analizować ruch na stronie. Kontynuując korzystanie z naszej witryny, zgadzasz się na wykorzystanie plików cookie.',
    btn: 'Zamknij',
  },
  HomePage: {
    title: 'Strona główna',
    menuList: {
      createStoryTitle: 'Stwórz historyjkę',
      createStoryDesc: 'Stwórz nową historyjkę dla użytkowników już teraz.',
      showStoriesTitle: 'Wszystkie historyjki',
      showStoriesDesc: 'Pokaz stworzone historyjki oraz sprawdź detale i statystyki.',
      personalDateTitle: 'Twoje dane',
      personalDateDesc: 'Zedytuj swoje dane widoczne dla użytkowników',
    },
  },
  example: {
    helloUser: 'I am a fake user, my name is {{name}}',
  },
  menu: {
    login: 'logowanie',
    home: 'strona główna',
    dashboard: 'panel',
    stories: 'medytacje',
    shop: 'Sklep subskrypcje',
    logout: 'wylogowanie',
  },
  language: {
    title: 'Język',
    pl: 'polski',
    en: 'angielski',
  },
  stories: {
    type: 'Typ',
    title: 'Tytuł',
    description: 'Opis',
    language: 'Język',
    createdAt: 'Utworzone w',
    photo: 'Zdjęcie',
    isFree: 'Za darmo',
    hashtags: 'Hashtags',
    availableDate: 'Data aktywacji',
    disable: 'Wyłączona',
  },
  storyPage: {
    title: 'Medytacje',
    modalVisibilityTitle: 'Wybierz, które kolumny mają być widoczne',
  },
  createStoryPage: {
    mainTitle: 'Stwórz nową historyjkę do medytacji',
    title: 'Tytuł',
    titlePlaceholder: 'Wprowadź tytuł historyjki...',
    desc: 'Opis',
    descPlaceholder: 'Wprowadź opis historyjki...',
    type: {
      title: 'Rodzaj',
      placeholder: 'Wybierz rodzaj historyjki...',
      SINGLE: 'Pojedncza medytacja',
      DAILY_SINGLE: 'Codzienna medytacja',
      SERIES_DAY_BY_DAY: 'Seria medytacji',
      SERIES_SAME_TOPIC: 'Medytacje w tym samym temacie',
      SERIES_TIME_LAPSE: 'Medytacje z różnymi czasami trwania',
      INTRODUCTION: 'Na główny ekran i stronę',
      ZENCAST_SINGLE: 'Pojedyczny zencast',
      ZENCAST_COURSE: 'kurs zencast',
    },
    language: 'Język',
    languagePlaceholder: 'Wybierz język historyjki...',
    isFree: 'Dostępne bez premium',
    availableDate: 'Data dostępności',
    hashtags: 'Etykiety',
    photo: 'Zdjęcie w miniaturce',
    photoBackground: 'Zdjecie w tle historyjki',
    meditationsTitle: 'Stwórz medytacje',
    createMeditation: {
      title: 'Medytacja numer: {{sequence}}',
      audio: 'Wczytaj audio',
      delete: 'Usuń',
      add: 'Dodaj kolejną medytację',
    },
    reset: 'Zresetuj pola',
    save: 'Zapisz historyjkę',
    error: {
      title: 'Proszę wprowadzić tytuł',
      desc: 'Proszę wprowadzić opis',
      type: 'Proszę wprowadzić typ',
      language: 'Proszę wprowadzić język',
      hashtags: 'Proszę wprowadzić przynajmniej jedną etykietę',
      photo: 'Proszę wprowadzić zdjęcie w tle',
      titleMeditation: 'Proszę wprowadzić tytuł medytacji',
      audio: 'Proszę wprowadzić audio medytacji',
    },
    loading: 'Proszę czekać, trwa zapisywanie historyjki',
    errorMain:
      'Wystąpił błąd, sprawdź czy wszystkie pola są uzupełnione poprawnie, jeżeli tak skontaktuj się z administratorem',
    success: 'Historyjka została poprawnie zapisana',
  },
  modal: {
    cancel: 'Zamknij',
    confirm: 'Potwierdź',
  },
  upload: {
    info: 'Przeciągnij plik tutaj lub kliknij aby wybrać odpowiedni plik',
    name: 'Nazwa pliku: ',
    duration: 'Długość: ',
    wrongType: 'Zły typ pliku, upewnij się czy plik jest w dobrym formacie lub skontaktuj się z administratorem',
  },
  storyType: {
    SINGLE: 'Pojedyncza medytacja',
    DAILY_SINGLE: 'Codzienna medytacja',
    SERIES_DAY_BY_DAY: 'Seria dzien po dniu',
    SERIES_SAME_TOPIC: 'Seria z tym samym tematem',
    SERIES_TIME_LAPSE: 'Seria z podziałem czasu',
  },
  hashtags: {
    relax: 'Spokój',
    work: 'Praca',
    parents: 'Dla rodzicow',
    breath: 'oddech',
    development: 'Rozwój',
    sleep: 'Sen',
    stress: 'Stres',
    focus: 'Skupienie',
    anxiety: 'Niepokój',
    beginers: 'Początkujący',
    'self-care': 'Troska o siebie',
    mother: 'Dla mam',
    health: 'Zdrowie',
    relationship: 'Relacje',
    happiness: 'Szczęscie',
    kids: 'Dla dzieci',
    program: 'Program',
  },
  Calendar: {
    days: {
      day0: 'Ndz',
      day1: 'Pn',
      day2: 'Wt',
      day3: 'Śr',
      day4: 'Czw',
      day5: 'Pt',
      day6: 'Sb',
    },
    daysLong: {
      day0: 'Niedziela',
      day1: 'Poniedziałek',
      day2: 'Wtorek',
      day3: 'Środa',
      day4: 'Czwartek',
      day5: 'Piątek',
      day6: 'Sobota',
    },
    month: {
      January: 'Sty',
      February: 'Luty',
      March: 'Mar',
      April: 'Kwi',
      May: 'Maj',
      June: 'Cze',
      July: 'Lip',
      August: 'Sie',
      September: 'Wrz',
      October: 'Paź',
      November: 'Lis',
      December: 'Gru',
    },
    monthLong: {
      January: 'Styczeń',
      February: 'Luty',
      March: 'Marzec',
      April: 'Kwiecień',
      May: 'Maj',
      June: 'Czerwiec',
      July: 'Lipiec',
      August: 'Sierpień',
      September: 'Wrzesień',
      October: 'Październik',
      November: 'Listopad',
      December: 'Grudzień',
    },
  },
  TimePicker: {
    save: 'Zapisz',
    cancel: 'Anuluj',
  },
  LanguageSelector: {
    title: 'Wybierz swój język',
  },
}
