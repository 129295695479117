import styles from "./PlayPage.module.css";
import ArrowBackIcon from "@images/mobile/back_arrow_gold.png";
import React from "react";
import ChestBonesImage from "@images/desktop/play/chest_bones.png";
import WalkingImage from "@images/desktop/play/walking.png";
import CaveRunImage from "@images/desktop/play/cave_run.png";

interface PlayPageProps {
    show: boolean;
    close: () => void;
}
export const PlayPage = ({show, close}: PlayPageProps) => {
    return (
        <div className={`${styles.root} ${show? styles.active: ''}`}>
            <div className={styles.frameContent}>
                    <div className={styles.frameDescription} style={{
                        backgroundImage: `url(${ChestBonesImage})`,
                        height: `144px`,
                        marginTop: `64px`
                    }} />
                    <div className={styles.frameDescription} style={{
                        backgroundImage: `url(${WalkingImage})`,
                        height: `144px`,
                        // marginTop: `89px`
                    }} />

                    <div className={styles.frameDescription} style={{
                        backgroundImage: `url(${CaveRunImage})`,
                        height: `144px`,
                        // marginTop: `103px`
                    }} />

            </div>
            <div className={`${styles.menuIcon}`} style={{
                backgroundImage: `url(${ArrowBackIcon})`,
            }} onClick={close}/>
        </div>
    )
}
