import { Dimmer, Loader } from 'semantic-ui-react'
import { Route, Router, Switch } from 'react-router-dom'
import React, { Suspense } from 'react'
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage'
import history from '../../shared/settings/history'
import PublicRoute from '../../shared/settings/router/PublicRoute'
import RouteEnum from './RouteEnum'
import Footer from '../components/Footer'
import styled from 'styled-components'
import { LandingPage } from '../pages/LandingPage/LandingPage'

export function Loading() {
  return (
    <Dimmer active>
      <Loader />
    </Dimmer>
  )
}

const footerHeight = '4rem'
const RouterContainer = styled.div`
  min-height: calc(100vh - ${footerHeight});
  max-width: 100vw;
  overflow: hidden;
  display: flex;
`

function AppRouter() {
  return (
    <Router history={history}>
      <Suspense fallback={<Loading />}>
          <Switch>
            <PublicRoute path={RouteEnum.Main} exact component={LandingPage} redirect={RouteEnum.Main} />
            <Route component={NotFoundPage} />
          </Switch>
        {/*<Footer height={footerHeight} />*/}
      </Suspense>
    </Router>
  )
}

export default AppRouter
