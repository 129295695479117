import React, {useState} from "react";
import styles from "./modalFrame.module.css";
import BackgroundImage from "@images/desktop/frame/background-frame.png";
import LeftBottomCorner from "@images/desktop/frame/Corner_b_l.png";
import RightBottomCorner from "@images/desktop/frame/Corner_b_r.png";
import LeftTopCorner from "@images/desktop/frame/Corner_t_l.png";
import RightTopCorner from "@images/desktop/frame/Corner_t_r.png";
import CloseImage from "@images/desktop/close.png";

export interface FrameProps {
    close: () => void;
    children?: React.ReactNode;
}
export function ModalFrame({close, children}: FrameProps) {
    return (
        <div style={{
            backgroundImage: `url(${BackgroundImage})`,
        }}
             className={`${styles.container}`}
          >
            <button className={`${styles.closeButton} `} style={{
                backgroundImage: `url(${CloseImage})`,
            }} onClick={close}/>
            <div style={{backgroundImage: `url(${LeftBottomCorner})`}} className={styles.LeftBottomCorner}/>
            <div style={{backgroundImage: `url(${RightBottomCorner})`}} className={styles.RightBottomCorner}/>
            <div style={{backgroundImage: `url(${LeftTopCorner})`}} className={styles.LeftTopCorner}/>
            <div style={{backgroundImage: `url(${RightTopCorner})`}} className={styles.RightTopCorner}/>

            <div className={`${styles.content}`}>{children}</div>

        </div>
    );
}
