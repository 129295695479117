import React, {useState} from "react";
import styles from "./Frame.module.css";
import BackgroundImage from "@images/desktop/frame/background-frame.png";
import LeftBottomCorner from "@images/desktop/frame/Corner_b_l.png";
import RightBottomCorner from "@images/desktop/frame/Corner_b_r.png";
import LeftTopCorner from "@images/desktop/frame/Corner_t_l.png";
import RightTopCorner from "@images/desktop/frame/Corner_t_r.png";

export interface FrameProps {
    children?: React.ReactNode;
}
export function Frame({children}: FrameProps) {

    const [showMenu, setShowMenu] = useState(false);
    return (
        <div style={{
            backgroundImage: `url(${BackgroundImage})`,
        }}
             className={`${styles.container} ${showMenu ? styles.active : ''}`}
             onMouseEnter={() => setShowMenu(true)}
             onMouseLeave={() => setShowMenu(false)}
          >
            <div style={{backgroundImage: `url(${LeftBottomCorner})`}} className={styles.LeftBottomCorner}/>
            <div style={{backgroundImage: `url(${RightBottomCorner})`}} className={styles.RightBottomCorner}/>
            <div style={{backgroundImage: `url(${LeftTopCorner})`}} className={styles.LeftTopCorner}/>
            <div style={{backgroundImage: `url(${RightTopCorner})`}} className={styles.RightTopCorner}/>

            <div
                className={`${styles.content} ${showMenu ? styles.active : ''}`}

            >{children}</div>

        </div>
    );
}
