// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import {useParallax} from "react-scroll-parallax";
import BackgroundImage from "@images/DoginHood_Background_WEBP.webp";
import CoverImage from "@images/DoginHood_Cover_WEBP.webp";
import ForegroundImage from "@images/DoginHood_Foreground_WEBP.webp";
import BackgroundModalImage from "@images/mobile/modal_background.png";
import BuyOverlay from "@images/desktop/overlay/buy_overlay.png";
import FishOverlay from "@images/desktop/overlay/fisherman_overlay.png";
import ComingSoon from "@images/desktop/overlay/coming_soon_overlay.png";
import BackgroundModelDesktopImage from "@images/mobile/modal_background.png";


import styles from "./ParallaExample.module.css";
import {calculatePosition} from "./service";
import {Campfire} from "../animations/Camfire";
import {Cave} from "../animations/Cave";
import {Chest} from "../animations/Chest";
import {Fish} from "../animations/Fish";

interface ParallaxDogProps {
    loading: boolean;
    onLoadEnd: () => void;
}

export default function ParallaxDog({loading, onLoadEnd}: ParallaxDogProps) {
    const [widthRoot, setWidthRoot] = useState('1000px');
    const [loadingStage, setLoadingStage] = useState({
        Background: false,
        Cover: false,
        Foreground: false,
        Campfire: false,
        Cave: false,
        Chest: false,
        Fish: false,
        BackgroundModalImage: false,
        BuyOverlay: false,
        FishOverlay: false,
        ComingSoon: false,
        BackgroundModelDesktopImage: false,
    });

    const target = useRef(null);
    const caveRef = useRef();
    const chestRef = useRef();
    const fishRef = useRef();

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                const setDimensions = () => {
                    const container = document.getElementById('background');
                    const originalImgBounds = container.getBoundingClientRect();
                    let newZoomedWidth = originalImgBounds.height * 5500 / 3094;
                    if (window.innerWidth > newZoomedWidth) {
                        newZoomedWidth = window.innerWidth;
                    }
                    setWidthRoot(`${newZoomedWidth}px`);
                    calculatePosition('campfire', container, {
                        width: 635,
                        height: 620,
                        x: 2768,
                        y: 1326,
                    }, newZoomedWidth);
                    calculatePosition('cave', container, {
                        width: 469,
                        height: 362,
                        x: 2662,
                        y: 834,
                    }, newZoomedWidth);
                    calculatePosition('chest', container, {
                        width: 1710,
                        height: 1293,
                        x: 0,
                        y: 1020,
                    }, newZoomedWidth);
                    calculatePosition('fish', container, {
                        width: 2143,
                        height: 779,
                        x: 1609,
                        y: 2252,
                    }, newZoomedWidth);


                    calculatePosition('cave-button', container, {
                        width: 469,
                        height: 362,
                        x: 2662,
                        y: 834,
                    }, newZoomedWidth);
                    calculatePosition('chest-button', container, {
                        width: 1710,
                        height: 1293,
                        x: 0,
                        y: 1020,
                    }, newZoomedWidth);
                    calculatePosition('fish-button', container, {
                        width: 2143,
                        height: 779,
                        x: 1609,
                        y: 2252,
                    }, newZoomedWidth);

                }
                setDimensions();
                window.addEventListener('load', setDimensions);
                window.addEventListener('resize', setDimensions);

            }, 10);
        }


        // return () => {
        //     window.removeEventListener('load', setDimensions);
        //     window.removeEventListener('resize', setDimensions);
        // }
    }, [loading])

    useEffect(() => {
        const middlePosition = parseInt(widthRoot) / 2;
        document.getElementById('root').scrollTo(middlePosition, 0);
    }, [widthRoot]);

    useEffect(() => {
        const loadingImage = (key: string, imageSrc: any) => {
            const image = new Image();
            image.onload = () => setLoadingStage((prevState) => ({...prevState, [key]: true}));
            image.src = imageSrc;
        }

        loadingImage('Background', BackgroundImage);
        loadingImage('Cover', CoverImage);
        loadingImage('Foreground', ForegroundImage);
        loadingImage('BackgroundModalImage', BackgroundModalImage);
        loadingImage('BackgroundModelDesktopImage', BackgroundModelDesktopImage);
        loadingImage('BuyOverlay', BuyOverlay);
        loadingImage('FishOverlay', FishOverlay);
        loadingImage('ComingSoon', ComingSoon);
    }, []);

    useEffect(() => {
        if (Object.values(loadingStage).every((val) => val)) {
            onLoadEnd()
        }
    }, [loadingStage, onLoadEnd]);

//   const mid = useParallax({
//     speed: 10,
//     targetElement: target.current,
//       // translateX: ['-50%', '50%'],
//   });
//   const mid2 = useParallax({
//     speed: 10,
//     targetElement: target.current,
//       // translateX: ['-50%', '50%'],
//   });
// const mid3 = useParallax({
//     speed: 10,
//     targetElement: target.current,
//     // translateX: ['-50%', '50%'],
//   });

    const caveStart = () => {
        caveRef.current.play();
    }

    const chestStart = () => {
        chestRef.current.play();
    }

    const fishStart = () => {
        fishRef.current.play();
    }
    return (
        <div className={styles.root} ref={target} style={{width: widthRoot}}>
            <div
                id="background"
                style={{
                    backgroundSize: `${widthRoot} 100vh`,
                    backgroundImage: `url(${BackgroundImage})`,
                    left: 0,
                    right: 0,
                }}
                className={styles.layer}
            >
                <Campfire id="campfire"
                          onLoadEnd={() => setLoadingStage((prevState) => ({...prevState, Campfire: true}))}/>
                <Cave id='cave' innerRef={caveRef}
                      onLoadEnd={() => setLoadingStage((prevState) => ({...prevState, Cave: true}))} />
                <Chest id='chest' innerRef={chestRef}
                       onLoadEnd={() => setLoadingStage((prevState) => ({...prevState, Chest: true}))}/>
                <Fish id='fish' innerRef={fishRef}
                      onLoadEnd={() => setLoadingStage((prevState) => ({...prevState, Fish: true}))}/>
            </div>
            <div
                style={{
                    backgroundSize: `${widthRoot} 100vh`,
                    backgroundImage: `url(${CoverImage})`,
                    left: 0,
                    right: 0,
                }}
                className={styles.layer}
            />

            <div
                style={{
                    backgroundSize: `${widthRoot} 100vh`,
                    backgroundImage: `url(${ForegroundImage})`,
                    left: 0,
                    right: 0,
                }}
                className={styles.layer}
            >
                <div id='cave-button' className={styles.button} onClick={caveStart}/>
                <div id='chest-button' className={styles.button} onClick={chestStart}/>
                <div id='fish-button' className={styles.button} onClick={fishStart}/>
            </div>
        </div>
    );
}
