interface PositionDetails {
    width: number;
    height: number;
    x: number;
    y: number;
}
const backgroundImageWidth = 5500;
const backgroundImageHeight = 3094;

export function calculatePosition(name: string, container: any, details: PositionDetails) {
    const {
        width: desiredImageWidth,
        height: desiredImageHeight,
        x: desiredX,
        y: desiredY,
    } = details

    const image = document.getElementById(name);

    // Calculate the scale factor for the image based on the background size
    const scaleX = container.clientWidth / backgroundImageWidth;
    const scaleY = container.clientHeight / backgroundImageHeight;

    // Calculate the actual position for the image based on the desired position
    const actualX = desiredX * scaleX;
    const actualY = desiredY * scaleY;

    // Calculate the actual dimensions for the image based on the desired dimensions
    const actualImageWidth = desiredImageWidth * scaleX;
    const actualImageHeight = desiredImageHeight * scaleY;

    // Set the styles for the image
    image!.style.width = `${actualImageWidth}px`;
    image!.style.height = `${actualImageHeight}px`;
    image!.style.top = `${actualY}px`;
    image!.style.left = `${actualX}px`;

}
