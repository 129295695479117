import React, {useMemo} from "react";

import TreasureChest_Anim_001 from '@images/sequence/chest/TreasureChest_Anim_001.webp';
import TreasureChest_Anim_002 from '@images/sequence/chest/TreasureChest_Anim_002.webp';
import TreasureChest_Anim_003 from '@images/sequence/chest/TreasureChest_Anim_003.webp';
import TreasureChest_Anim_004 from '@images/sequence/chest/TreasureChest_Anim_004.webp';
import TreasureChest_Anim_005 from '@images/sequence/chest/TreasureChest_Anim_005.webp';
import TreasureChest_Anim_006 from '@images/sequence/chest/TreasureChest_Anim_006.webp';
import TreasureChest_Anim_007 from '@images/sequence/chest/TreasureChest_Anim_007.webp';
import TreasureChest_Anim_008 from '@images/sequence/chest/TreasureChest_Anim_008.webp';
import TreasureChest_Anim_009 from '@images/sequence/chest/TreasureChest_Anim_009.webp';
import TreasureChest_Anim_010 from '@images/sequence/chest/TreasureChest_Anim_010.webp';
import TreasureChest_Anim_011 from '@images/sequence/chest/TreasureChest_Anim_011.webp';
import TreasureChest_Anim_012 from '@images/sequence/chest/TreasureChest_Anim_012.webp';
import TreasureChest_Anim_013 from '@images/sequence/chest/TreasureChest_Anim_013.webp';
import TreasureChest_Anim_014 from '@images/sequence/chest/TreasureChest_Anim_014.webp';
import TreasureChest_Anim_015 from '@images/sequence/chest/TreasureChest_Anim_015.webp';
import TreasureChest_Anim_016 from '@images/sequence/chest/TreasureChest_Anim_016.webp';
import TreasureChest_Anim_017 from '@images/sequence/chest/TreasureChest_Anim_017.webp';
import TreasureChest_Anim_018 from '@images/sequence/chest/TreasureChest_Anim_018.webp';
import TreasureChest_Anim_019 from '@images/sequence/chest/TreasureChest_Anim_019.webp';
import TreasureChest_Anim_020 from '@images/sequence/chest/TreasureChest_Anim_020.webp';
import TreasureChest_Anim_021 from '@images/sequence/chest/TreasureChest_Anim_021.webp';
import TreasureChest_Anim_022 from '@images/sequence/chest/TreasureChest_Anim_022.webp';
import TreasureChest_Anim_023 from '@images/sequence/chest/TreasureChest_Anim_023.webp';
import TreasureChest_Anim_024 from '@images/sequence/chest/TreasureChest_Anim_024.webp';
import TreasureChest_Anim_025 from '@images/sequence/chest/TreasureChest_Anim_025.webp';
import TreasureChest_Anim_026 from '@images/sequence/chest/TreasureChest_Anim_026.webp';
import TreasureChest_Anim_027 from '@images/sequence/chest/TreasureChest_Anim_027.webp';
import TreasureChest_Anim_028 from '@images/sequence/chest/TreasureChest_Anim_028.webp';
import TreasureChest_Anim_029 from '@images/sequence/chest/TreasureChest_Anim_029.webp';
import TreasureChest_Anim_030 from '@images/sequence/chest/TreasureChest_Anim_030.webp';
import TreasureChest_Anim_031 from '@images/sequence/chest/TreasureChest_Anim_031.webp';
import TreasureChest_Anim_032 from '@images/sequence/chest/TreasureChest_Anim_032.webp';


import styles from "./Campfire.module.css";
import {SequenceAnimation} from "../../SequenceAnimation/SequenceAnimation";
import {useImagesLoaded} from "../../service/useImagesLoaded";
interface Props {
    id: string;
    innerRef: any;
    onLoadEnd: () => void;
}
export const Chest = ({id, innerRef, onLoadEnd}: Props) => {
    const images = useMemo(() => {
        return [
        TreasureChest_Anim_001,
        TreasureChest_Anim_002,
        TreasureChest_Anim_003,
        TreasureChest_Anim_004,
        TreasureChest_Anim_005,
        TreasureChest_Anim_006,
        TreasureChest_Anim_007,
        TreasureChest_Anim_008,
        TreasureChest_Anim_009,
        TreasureChest_Anim_010,
        TreasureChest_Anim_011,
        TreasureChest_Anim_012,
        TreasureChest_Anim_013,
        TreasureChest_Anim_014,
        TreasureChest_Anim_015,
        TreasureChest_Anim_016,
        TreasureChest_Anim_017,
        TreasureChest_Anim_018,
        TreasureChest_Anim_019,
        TreasureChest_Anim_020,
        TreasureChest_Anim_021,
        TreasureChest_Anim_022,
        TreasureChest_Anim_023,
        TreasureChest_Anim_024,
        TreasureChest_Anim_025,
        TreasureChest_Anim_026,
        TreasureChest_Anim_027,
        TreasureChest_Anim_028,
        TreasureChest_Anim_029,
        TreasureChest_Anim_030,
        TreasureChest_Anim_031,
        TreasureChest_Anim_032,
    ]}, []);
    useImagesLoaded(images, onLoadEnd);

    return (
        <div id={id} className={styles.container}>
            <SequenceAnimation images={images} ref={innerRef} frameRate={55}/>
        </div>
    )
}
