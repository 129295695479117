import styles from "./WaitListPage.module.css";
import ArrowBackIcon from "@images/mobile/back_arrow_green.png";
import React from "react";
import TwitterLogo from "@images/mobile/twitter_logo.png";
import SolanaLogo from "@images/mobile/solana_logo.png";
import Banner from "@images/mobile/Banner.png";
import InputBackground from "@images/mobile/input.png";
import {Icon} from "semantic-ui-react";
import {useWalletAddress} from "../../../../../../../shared/api/useWalletAddress";

interface PlayPageProps {
    show: boolean;
    close: () => void;
}
export const WaitListPage = ({show, close}: PlayPageProps) => {
    const [address, setAddress] = React.useState(''); // [1]
    const {loading, error, data, saveWalletAddress} = useWalletAddress();

    const redirectToTwitter = () => {
        window.open('https://twitter.com/doginhood_io', '_blank');
    }

    return (
        <div className={`${styles.root} ${show? styles.active: ''}`}>
            <div className={`${styles.banner}`} style={{
                backgroundImage: `url(${Banner})`,
            }}/>
            <div className={styles.content}>
                <div className={styles.item}>
                    <div className={styles.inline}>
                        <div className={styles.itemText}>Enter your EVM wallet address</div>
                    </div>
                    <div className={styles.inlineBetween}>
                        <input type="text"
                               value={address}
                               onChange={(e) => setAddress(e.target.value)}
                               className={styles.input}
                               style={{backgroundImage: `url(${InputBackground})`}}
                               placeholder="Enter your EVM wallet address" />
                        <button className={styles.button} onClick={() => saveWalletAddress(address)}>
                            {!loading && <Icon name='checkmark' size='large' className={styles.white}/>}
                            {loading && <Icon loading name='spinner' size='large'  className={styles.white} />}
                        </button>
                    </div>
                    {error && error !== 'P2002' && <div className={styles.error}>Internal server error</div>}
                    {error && error === 'P2002' && <div className={styles.error}>Wallet address already exist.</div>}
                    {data && <div className={styles.success}>Your wallet address saved.</div>}
                </div>

                <div className={styles.separator} />

                <button className={styles.inlineBetween} onClick={redirectToTwitter}>
                    <div className={styles.inline}>
                        <div className={styles.itemText}>Follow on X</div>
                    </div>
                    <button className={styles.button} onClick={redirectToTwitter}>
                        <Icon name='share alternate' size='large' className={styles.white}/>
                    </button>
                </button>
                <div className={`${styles.menuIcon}`} style={{
                    backgroundImage: `url(${ArrowBackIcon})`,
                }} onClick={close}/>
            </div>

        </div>
    )
}
