import React from "react";
import styles from './WaitListPage.module.css';
import {ModalFrame} from "../../components/modalFrame/ModalFrame";
import TwitterLogo from "@images/desktop/twitter_logo.png";
import SolanaLogo from "@images/desktop/solana_logo.png";
import InputBackground from "@images/desktop/input.png";
import {Icon} from "semantic-ui-react";
import {useWalletAddress} from "../../../../../../../shared/api/useWalletAddress";

interface BuyPageProps {
    show: boolean;
    close: () => void;
}
export const WaitListPage = ({show, close}: BuyPageProps) => {
        const [address, setAddress] = React.useState(''); // [1]
        const {loading, error, data, saveWalletAddress} = useWalletAddress();
    const redirectToTwitter = () => {
        window.open('https://twitter.com/doginhood_io', '_blank');
    }
        return (
            <div className={`${styles.container} ${show ? styles.containerActive: ''}`} style={{pointerEvents: show? 'auto': 'none'}}>
                <ModalFrame close={close}>
                    <div className={styles.content}>
                        <div className={styles.item}>
                            <div className={styles.inline}>
                                <div className={styles.solanaIcon}  style={{backgroundImage: `url(${SolanaLogo})`}}/>
                                <div className={styles.itemText}>Enter your EVM wallet address</div>
                            </div>
                            <div className={styles.inlineBetween}>
                                <input type="text"
                                       value={address}
                                       onChange={(e) => setAddress(e.target.value)}
                                       className={styles.input}
                                       style={{backgroundImage: `url(${InputBackground})`}}
                                       placeholder="Enter your EVM wallet address" />
                                <button className={styles.button} onClick={() => saveWalletAddress(address)}>
                                    {!loading && <Icon name='checkmark' size='large' className={styles.white}/>}
                                    {loading && <Icon loading name='spinner' size='large'  className={styles.white} />}
                                </button>
                            </div>
                            {error && error !== 'P2002' && <div className={styles.error}>Internal server error</div>}
                            {error && error === 'P2002' && <div className={styles.error}>Wallet address already exist.</div>}
                            {data && <div className={styles.success}>Your wallet address saved.</div>}
                        </div>

                        <div className={styles.separator} />

                        <div className={styles.inlineBetween}>
                            <div className={styles.inline_v2}>
                            <div className={styles.twitterIcon}  style={{backgroundImage: `url(${TwitterLogo})`}}/>
                            <div className={styles.itemText}>Follow on X</div>
                        </div>
                        <button className={styles.button} onClick={redirectToTwitter}>
                            <Icon name='share alternate' size='large' className={styles.white}/>
                        </button>
                        </div>

                    </div>
                </ModalFrame>
            </div>
        )

}
