import {useEffect, useState} from "react";

const updateAtIndex = (arr: Array<any>, index: number, newValue: any) => {
    const newArray = [...arr];
    newArray[index] = newValue;
    return newArray;
};

export function useImagesLoaded(images: Array<any>, onLoadedEnd: () => void) {
    const [imagesLoaded, setImagesLoaded] = useState(images.map(() => false))

    useEffect(() => {
        images.forEach((imageSrc, index) => {
            const image = new Image();
            image.onload = () => setImagesLoaded((prevState) => updateAtIndex(prevState, index, true));
            image.src = imageSrc;
        })
    }, []);

    useEffect(() => {
        if (imagesLoaded.every((imageLoaded) => imageLoaded)) {
            onLoadedEnd()
        }
    }, [imagesLoaded]);
}
