import React, { useMemo } from 'react'

import DoginHood_UI_LaunchApp_COMP_00000 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00000.webp'
import DoginHood_UI_LaunchApp_COMP_00002 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00002.webp'
import DoginHood_UI_LaunchApp_COMP_00003 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00003.webp'
import DoginHood_UI_LaunchApp_COMP_00004 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00004.webp'
import DoginHood_UI_LaunchApp_COMP_00005 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00005.webp'
import DoginHood_UI_LaunchApp_COMP_00006 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00006.webp'
import DoginHood_UI_LaunchApp_COMP_00007 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00007.webp'
import DoginHood_UI_LaunchApp_COMP_00008 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00008.webp'
import DoginHood_UI_LaunchApp_COMP_00009 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00009.webp'
import DoginHood_UI_LaunchApp_COMP_00010 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00010.webp'
import DoginHood_UI_LaunchApp_COMP_00011 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00011.webp'
import DoginHood_UI_LaunchApp_COMP_00012 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00012.webp'
import DoginHood_UI_LaunchApp_COMP_00013 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00013.webp'
import DoginHood_UI_LaunchApp_COMP_00014 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00014.webp'
import DoginHood_UI_LaunchApp_COMP_00015 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00015.webp'
import DoginHood_UI_LaunchApp_COMP_00016 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00016.webp'
import DoginHood_UI_LaunchApp_COMP_00017 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00017.webp'
import DoginHood_UI_LaunchApp_COMP_00018 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00018.webp'
import DoginHood_UI_LaunchApp_COMP_00019 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00019.webp'
import DoginHood_UI_LaunchApp_COMP_00020 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00020.webp'
import DoginHood_UI_LaunchApp_COMP_00021 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00021.webp'
import DoginHood_UI_LaunchApp_COMP_00022 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00022.webp'
import DoginHood_UI_LaunchApp_COMP_00023 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00023.webp'
import DoginHood_UI_LaunchApp_COMP_00024 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00024.webp'
import DoginHood_UI_LaunchApp_COMP_00025 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00025.webp'
import DoginHood_UI_LaunchApp_COMP_00026 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00026.webp'
import DoginHood_UI_LaunchApp_COMP_00027 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00027.webp'
import DoginHood_UI_LaunchApp_COMP_00028 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00028.webp'
import DoginHood_UI_LaunchApp_COMP_00029 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00029.webp'
import DoginHood_UI_LaunchApp_COMP_00030 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00030.webp'
import DoginHood_UI_LaunchApp_COMP_00031 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00031.webp'
import DoginHood_UI_LaunchApp_COMP_00032 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00032.webp'
import DoginHood_UI_LaunchApp_COMP_00033 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00033.webp'
import DoginHood_UI_LaunchApp_COMP_00034 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00034.webp'
import DoginHood_UI_LaunchApp_COMP_00035 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00035.webp'
import DoginHood_UI_LaunchApp_COMP_00036 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00036.webp'
import DoginHood_UI_LaunchApp_COMP_00037 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00037.webp'
import DoginHood_UI_LaunchApp_COMP_00038 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00038.webp'
import DoginHood_UI_LaunchApp_COMP_00039 from '@images/launchapp_baner/DoginHood_UI_LaunchApp_COMP_00039.webp'

import styles from './Campfire.module.css'
import { useImagesLoaded } from '../../../../service/useImagesLoaded'
import { SequenceAnimation } from '../../../../SequenceAnimation/SequenceAnimation'

interface Props {
  id: string
  innerRef: any
  onLoadEnd: () => void
}
export const LaunchAppBtnAnim = ({ id, innerRef, onLoadEnd }: Props) => {
  const images = useMemo(() => {
    return [
      DoginHood_UI_LaunchApp_COMP_00000,
      DoginHood_UI_LaunchApp_COMP_00002,
      DoginHood_UI_LaunchApp_COMP_00003,
      DoginHood_UI_LaunchApp_COMP_00004,
      DoginHood_UI_LaunchApp_COMP_00005,
      DoginHood_UI_LaunchApp_COMP_00006,
      DoginHood_UI_LaunchApp_COMP_00007,
      DoginHood_UI_LaunchApp_COMP_00008,
      DoginHood_UI_LaunchApp_COMP_00009,
      DoginHood_UI_LaunchApp_COMP_00010,
      DoginHood_UI_LaunchApp_COMP_00011,
      DoginHood_UI_LaunchApp_COMP_00012,
      DoginHood_UI_LaunchApp_COMP_00013,
      DoginHood_UI_LaunchApp_COMP_00014,
      DoginHood_UI_LaunchApp_COMP_00015,
      DoginHood_UI_LaunchApp_COMP_00016,
      DoginHood_UI_LaunchApp_COMP_00017,
      DoginHood_UI_LaunchApp_COMP_00018,
      DoginHood_UI_LaunchApp_COMP_00019,
      DoginHood_UI_LaunchApp_COMP_00020,
      DoginHood_UI_LaunchApp_COMP_00021,
      DoginHood_UI_LaunchApp_COMP_00022,
      DoginHood_UI_LaunchApp_COMP_00023,
      DoginHood_UI_LaunchApp_COMP_00024,
      DoginHood_UI_LaunchApp_COMP_00025,
      DoginHood_UI_LaunchApp_COMP_00026,
      DoginHood_UI_LaunchApp_COMP_00027,
      DoginHood_UI_LaunchApp_COMP_00028,
      DoginHood_UI_LaunchApp_COMP_00029,
      DoginHood_UI_LaunchApp_COMP_00030,
      DoginHood_UI_LaunchApp_COMP_00031,
      DoginHood_UI_LaunchApp_COMP_00032,
      DoginHood_UI_LaunchApp_COMP_00033,
      DoginHood_UI_LaunchApp_COMP_00034,
      DoginHood_UI_LaunchApp_COMP_00035,
      DoginHood_UI_LaunchApp_COMP_00036,
      DoginHood_UI_LaunchApp_COMP_00037,
      DoginHood_UI_LaunchApp_COMP_00038,
      DoginHood_UI_LaunchApp_COMP_00039,
    ]
  }, [])
  useImagesLoaded(images, onLoadEnd)

  return (
    <div id={id} className={styles.container}>
      <SequenceAnimation images={images} ref={innerRef} frameRate={15} loop autoPlay />
    </div>
  )
}
