import React, {useMemo, useState} from "react";
import Campfire_Anim_Preloader_000 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_000.webp';
import Campfire_Anim_Preloader_001 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_001.webp';
import Campfire_Anim_Preloader_002 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_002.webp';
import Campfire_Anim_Preloader_003 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_003.webp';
import Campfire_Anim_Preloader_004 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_004.webp';
import Campfire_Anim_Preloader_005 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_005.webp';
import Campfire_Anim_Preloader_006 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_006.webp';
import Campfire_Anim_Preloader_007 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_007.webp';
import Campfire_Anim_Preloader_008 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_008.webp';
import Campfire_Anim_Preloader_009 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_009.webp';
import Campfire_Anim_Preloader_010 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_010.webp';
import Campfire_Anim_Preloader_011 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_011.webp';
import Campfire_Anim_Preloader_012 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_012.webp';
import Campfire_Anim_Preloader_013 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_013.webp';
import Campfire_Anim_Preloader_014 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_014.webp';
import Campfire_Anim_Preloader_015 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_015.webp';
import Campfire_Anim_Preloader_016 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_016.webp';
import Campfire_Anim_Preloader_017 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_017.webp';
import Campfire_Anim_Preloader_018 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_018.webp';
import Campfire_Anim_Preloader_019 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_019.webp';
import Campfire_Anim_Preloader_020 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_020.webp';
import Campfire_Anim_Preloader_021 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_021.webp';
import Campfire_Anim_Preloader_022 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_022.webp';
import Campfire_Anim_Preloader_023 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_023.webp';
import Campfire_Anim_Preloader_024 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_024.webp';
import Campfire_Anim_Preloader_025 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_025.webp';
import Campfire_Anim_Preloader_026 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_026.webp';
import Campfire_Anim_Preloader_027 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_027.webp';
import Campfire_Anim_Preloader_028 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_028.webp';
import Campfire_Anim_Preloader_029 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_029.webp';
import Campfire_Anim_Preloader_030 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_030.webp';
import Campfire_Anim_Preloader_031 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_031.webp';
import Campfire_Anim_Preloader_032 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_032.webp';
import Campfire_Anim_Preloader_033 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_033.webp';
import Campfire_Anim_Preloader_034 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_034.webp';
import Campfire_Anim_Preloader_035 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_035.webp';
import Campfire_Anim_Preloader_036 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_036.webp';
import Campfire_Anim_Preloader_037 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_037.webp';
import Campfire_Anim_Preloader_038 from '@images/sequence/campfire-loader/Campfire_Anim_Preloader_038.webp';

import styles from "./Campfire.module.css";
import {SequenceAnimation} from "../../SequenceAnimation/SequenceAnimation";
import {useImagesLoaded} from "../../service/useImagesLoaded";
import {Loader} from "semantic-ui-react";

interface CamfireLoaderProps{
    onLoadEnd: () => void;
}
export const CamfireLoader = ({onLoadEnd}: CamfireLoaderProps) => {
    const [loading, setLoading] = useState(true);
    const images = useMemo(() => {
        return [
            Campfire_Anim_Preloader_000,
            Campfire_Anim_Preloader_001,
            Campfire_Anim_Preloader_002,
            Campfire_Anim_Preloader_003,
            Campfire_Anim_Preloader_004,
            Campfire_Anim_Preloader_005,
            Campfire_Anim_Preloader_006,
            Campfire_Anim_Preloader_007,
            Campfire_Anim_Preloader_008,
            Campfire_Anim_Preloader_009,
            Campfire_Anim_Preloader_010,
            Campfire_Anim_Preloader_011,
            Campfire_Anim_Preloader_012,
            Campfire_Anim_Preloader_013,
            Campfire_Anim_Preloader_014,
            Campfire_Anim_Preloader_015,
            Campfire_Anim_Preloader_016,
            Campfire_Anim_Preloader_017,
            Campfire_Anim_Preloader_018,
            Campfire_Anim_Preloader_019,
            Campfire_Anim_Preloader_020,
            Campfire_Anim_Preloader_021,
            Campfire_Anim_Preloader_022,
            Campfire_Anim_Preloader_023,
            Campfire_Anim_Preloader_024,
            Campfire_Anim_Preloader_025,
            Campfire_Anim_Preloader_026,
            Campfire_Anim_Preloader_027,
            Campfire_Anim_Preloader_028,
            Campfire_Anim_Preloader_029,
            Campfire_Anim_Preloader_030,
            Campfire_Anim_Preloader_031,
            Campfire_Anim_Preloader_032,
            Campfire_Anim_Preloader_033,
            Campfire_Anim_Preloader_034,
            Campfire_Anim_Preloader_035,
            Campfire_Anim_Preloader_036,
            Campfire_Anim_Preloader_037,
            Campfire_Anim_Preloader_038,
        ]
    }, []);
    useImagesLoaded(images, () => {
        setLoading(false);
        onLoadEnd();
    });
    return (
        <div className={styles.container}>
            {!loading && <SequenceAnimation images={images} autoPlay loop frameRate={50}/>}
        </div>
    )
}
