// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";

interface Props {
    images: Array<any>;
    loop?: boolean;
    autoPlay?: boolean;
    frameRate?: number;
}
export const SequenceAnimation = forwardRef(
    (
        {
            images,
            loop = false,
            autoPlay = false,
            frameRate = 100,
        }: Props,
        ref
    ) => {

        useImperativeHandle(ref, () => ({
            play() {
                playSequence()
            },
            stop() {
                stopSequence();
            }
        }));

        useEffect(() => {
            if (autoPlay) {
                playSequence()
            }
        }, [autoPlay]);
        const [currentIndex, setCurrentIndex] = useState(0);
        const intervalRef = useRef(null);

        const playSequence = () => {
            // Clear any existing interval
            clearInterval(intervalRef.current);

            // Set up a new interval to advance the animation
            intervalRef.current = setInterval(() => {
                setCurrentIndex((prevIndex: number) => {
                    const nextIndex = (prevIndex + 1) % images.length;

                    // Check if the animation has reached the last frame
                    if (!loop && nextIndex === 0) {
                        stopSequence();
                    }

                    return nextIndex;
                });
            }, frameRate); // Adjust the interval time as needed
        };
        const stopSequence = () => {
            // @ts-ignore
            clearInterval(intervalRef?.current);
        };

        const resetSequence = () => {
            // Stop the animation and reset the index to the beginning
            stopSequence();
            setCurrentIndex(0);
        };

        // @ts-ignore
        return <img src={images[currentIndex]} style={{width: '100%', height: '100%'}} alt={`Frame ${currentIndex}`} />
    });
