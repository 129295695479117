import React, { useRef, useState } from 'react'
import styles from './Menu.module.css'
import BackgroundImage from '@images/desktop/menu-background.png'
import LaunchappButtonBg from '@images/desktop/menu/lanuchpad_banner.webp'

import BurgerIcon from '@images/icons/hamurger_menu.png'
import KeyboardUpIcon from '@images/icons/keyboard_arrow_up.png'
import { MenuItems } from '../../../../shared/menu.const'
import { APP_CONFIG } from '../../../../../../../config'
import { Button } from 'semantic-ui-react'
import { LaunchAppBtnAnim } from './LaunchApp'

interface MenuProps {
  onChange: (value: MenuItems) => void
}

export function Menu({ onChange }: MenuProps) {
  const launchAppRef = useRef()
  const [showMenu, setShowMenu] = useState(false)

  const [hovered, setHovered] = useState(false)
  const toggleHover = () => setHovered(!hovered)

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}
        className={`${styles.container} ${showMenu ? styles.active : ''}`}
      >
        <div className={styles.menuItems}>
          <button className={styles.menuItem} onClick={() => (window.location.href = APP_CONFIG.GAME_HOST)}>
            {MenuItems.PLAY}
          </button>
          <button className={styles.menuItem} onClick={() => onChange(MenuItems.BUY)}>
            {MenuItems.BUY}
          </button>
          <button className={styles.menuItem} onClick={() => onChange(MenuItems.STAKE)}>
            {MenuItems.STAKE}
          </button>
          {/*<button className={styles.menuItem} onClick={() => onChange(MenuItems.LAUNCHPAD)}>{MenuItems.LAUNCHPAD}</button>*/}
          <button className={styles.menuItem} onClick={() => (window.location.href = APP_CONFIG.DOCS_HOST)}>
            {MenuItems.DOCS}
          </button>
          <button className={styles.menuItem} onClick={() => onChange(MenuItems.LOTTERY)}>
            {MenuItems.LOTTERY}
          </button>
          <button className={styles.menuItem} onClick={() => onChange(MenuItems.LEADERBOARD)}>
            {MenuItems.LEADERBOARD}
          </button>
          <div>
            <Button
              circular
              color="twitter"
              icon="twitter"
              onClick={() => (window.location.href = APP_CONFIG.TWITTER_HOST)}
            />
            <Button
              circular
              color="purple"
              icon="discord"
              onClick={() => (window.location.href = APP_CONFIG.DISCORD_HOST)}
            />
          </div>
        </div>
        <div className={styles.menuIconContent}>
          <button
            className={showMenu ? styles.menuIconKeyboardUp : styles.menuIconBurger}
            style={{ backgroundImage: `url(${showMenu ? KeyboardUpIcon : BurgerIcon})` }}
            onClick={() => setShowMenu(!showMenu)}
          />
        </div>
      </div>

      <button
        style={{
          backgroundImage: `url(${LaunchappButtonBg})`,
        }}
        className={styles.waitListContainer}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        onClick={() => onChange(MenuItems.LEADERBOARD)}
      ></button>

      <div className={styles.launchAppAnim} style={{ display: hovered ? 'block' : 'none' }}>
        <LaunchAppBtnAnim id="launchapp" innerRef={launchAppRef} onLoadEnd={() => {}} />
      </div>
    </div>
  )
}
