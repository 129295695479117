import {Modal} from "../../components/modal/Modal";
import styles from "./StakePage.module.css";
import ComingSoonOverlay from "@images/desktop/overlay/coming_soon_overlay.png";
import {useEffect, useState} from "react";

interface BuyPageProps {
    show: boolean;
    close: () => void;
}
export const StakePage = ({show, close}: BuyPageProps) => {
    const [zoomedWidth, setZoomedWidth] = useState(0);

    useEffect(() => {
        const setDimensions = () => {
            setZoomedWidth(window.innerWidth * 1.1 * 0.95 - 318);
        }
        setDimensions();
        window.addEventListener('load', setDimensions);
        window.addEventListener('resize', setDimensions);


        return () => {
            window.removeEventListener('load', setDimensions);
            window.removeEventListener('resize', setDimensions);
        }
    }, [])

    return (
        <Modal show={show} close={close}>
            <div className={styles.cover} style={{
                backgroundImage: `url(${ComingSoonOverlay})`,
                width: `${zoomedWidth}px`,
                height: `${1029* zoomedWidth / 1626}px`,
            }} />

            <div className={styles.content}>
                <div className={styles.title}>Coming <br /> soon</div>
            </div>
        </Modal>
    )
}
