export enum MenuItems {
    PLAY = 'PLAY',
    BUY = 'BUY',
    STAKE = 'STAKE',
    LAUNCHPAD = 'LAUNCHPAD',
    DOCS = 'DOCS',
    LOTTERY = 'LOTTERY',
    LEADERBOARD = 'LEADERBOARD',
    WAITLIST = 'WAITLIST',
}

