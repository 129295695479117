import React, {useMemo} from "react";

import Cave_anim_000 from '@images/sequence/cave/Cave_anim_000.webp';
import Cave_anim_001 from '@images/sequence/cave/Cave_anim_001.webp';
import Cave_anim_002 from '@images/sequence/cave/Cave_anim_002.webp';
import Cave_anim_003 from '@images/sequence/cave/Cave_anim_003.webp';
import Cave_anim_004 from '@images/sequence/cave/Cave_anim_004.webp';
import Cave_anim_005 from '@images/sequence/cave/Cave_anim_005.webp';
import Cave_anim_006 from '@images/sequence/cave/Cave_anim_006.webp';
import Cave_anim_007 from '@images/sequence/cave/Cave_anim_007.webp';
import Cave_anim_008 from '@images/sequence/cave/Cave_anim_008.webp';
import Cave_anim_009 from '@images/sequence/cave/Cave_anim_009.webp';
import Cave_anim_010 from '@images/sequence/cave/Cave_anim_010.webp';
import Cave_anim_011 from '@images/sequence/cave/Cave_anim_011.webp';
import Cave_anim_012 from '@images/sequence/cave/Cave_anim_012.webp';
import Cave_anim_013 from '@images/sequence/cave/Cave_anim_013.webp';
import Cave_anim_014 from '@images/sequence/cave/Cave_anim_014.webp';
import Cave_anim_015 from '@images/sequence/cave/Cave_anim_015.webp';
import Cave_anim_016 from '@images/sequence/cave/Cave_anim_016.webp';
import Cave_anim_017 from '@images/sequence/cave/Cave_anim_017.webp';
import Cave_anim_018 from '@images/sequence/cave/Cave_anim_018.webp';
import Cave_anim_019 from '@images/sequence/cave/Cave_anim_019.webp';
import Cave_anim_020 from '@images/sequence/cave/Cave_anim_020.webp';
import Cave_anim_021 from '@images/sequence/cave/Cave_anim_021.webp';
import Cave_anim_022 from '@images/sequence/cave/Cave_anim_022.webp';
import Cave_anim_023 from '@images/sequence/cave/Cave_anim_023.webp';
import Cave_anim_024 from '@images/sequence/cave/Cave_anim_024.webp';
import Cave_anim_025 from '@images/sequence/cave/Cave_anim_025.webp';
import Cave_anim_026 from '@images/sequence/cave/Cave_anim_026.webp';
import Cave_anim_027 from '@images/sequence/cave/Cave_anim_027.webp';
import Cave_anim_028 from '@images/sequence/cave/Cave_anim_028.webp';
import Cave_anim_029 from '@images/sequence/cave/Cave_anim_029.webp';
import Cave_anim_030 from '@images/sequence/cave/Cave_anim_030.webp';
import Cave_anim_031 from '@images/sequence/cave/Cave_anim_031.webp';
import Cave_anim_032 from '@images/sequence/cave/Cave_anim_032.webp';
import Cave_anim_033 from '@images/sequence/cave/Cave_anim_033.webp';
import Cave_anim_034 from '@images/sequence/cave/Cave_anim_034.webp';
import Cave_anim_035 from '@images/sequence/cave/Cave_anim_035.webp';
import Cave_anim_036 from '@images/sequence/cave/Cave_anim_036.webp';
import Cave_anim_037 from '@images/sequence/cave/Cave_anim_037.webp';
import Cave_anim_038 from '@images/sequence/cave/Cave_anim_038.webp';
import Cave_anim_039 from '@images/sequence/cave/Cave_anim_039.webp';
import Cave_anim_040 from '@images/sequence/cave/Cave_anim_040.webp';
import Cave_anim_041 from '@images/sequence/cave/Cave_anim_041.webp';
import Cave_anim_042 from '@images/sequence/cave/Cave_anim_042.webp';
import Cave_anim_043 from '@images/sequence/cave/Cave_anim_043.webp';
import Cave_anim_044 from '@images/sequence/cave/Cave_anim_044.webp';
import Cave_anim_045 from '@images/sequence/cave/Cave_anim_045.webp';
import Cave_anim_046 from '@images/sequence/cave/Cave_anim_046.webp';
import Cave_anim_047 from '@images/sequence/cave/Cave_anim_047.webp';
import Cave_anim_048 from '@images/sequence/cave/Cave_anim_048.webp';
import Cave_anim_049 from '@images/sequence/cave/Cave_anim_049.webp';
import Cave_anim_050 from '@images/sequence/cave/Cave_anim_050.webp';
import Cave_anim_051 from '@images/sequence/cave/Cave_anim_051.webp';
import Cave_anim_052 from '@images/sequence/cave/Cave_anim_052.webp';
import Cave_anim_053 from '@images/sequence/cave/Cave_anim_053.webp';

import styles from "./Campfire.module.css";
import {SequenceAnimation} from "../../SequenceAnimation/SequenceAnimation";
import {useImagesLoaded} from "../../service/useImagesLoaded";
interface Props {
    id: string;
    innerRef: any;
    onLoadEnd: () => void;
}
export const Cave = ({id, innerRef, onLoadEnd}: Props) => {
    const images = useMemo(() => {
        return [
            Cave_anim_000,
            Cave_anim_001,
            Cave_anim_002,
            Cave_anim_003,
            Cave_anim_004,
            Cave_anim_005,
            Cave_anim_006,
            Cave_anim_007,
            Cave_anim_008,
            Cave_anim_009,
            Cave_anim_010,
            Cave_anim_011,
            Cave_anim_012,
            Cave_anim_013,
            Cave_anim_014,
            Cave_anim_015,
            Cave_anim_016,
            Cave_anim_017,
            Cave_anim_018,
            Cave_anim_019,
            Cave_anim_020,
            Cave_anim_021,
            Cave_anim_022,
            Cave_anim_023,
            Cave_anim_024,
            Cave_anim_025,
            Cave_anim_026,
            Cave_anim_027,
            Cave_anim_028,
            Cave_anim_029,
            Cave_anim_030,
            Cave_anim_031,
            Cave_anim_032,
            Cave_anim_033,
            Cave_anim_034,
            Cave_anim_035,
            Cave_anim_036,
            Cave_anim_037,
            Cave_anim_038,
            Cave_anim_039,
            Cave_anim_040,
            Cave_anim_041,
            Cave_anim_042,
            Cave_anim_043,
            Cave_anim_044,
            Cave_anim_045,
            Cave_anim_046,
            Cave_anim_047,
            Cave_anim_048,
            Cave_anim_049,
            Cave_anim_050,
            Cave_anim_051,
            Cave_anim_052,
            Cave_anim_053,
        ]
    }, [])
    useImagesLoaded(images, onLoadEnd);
    return (
        <div id={id} className={styles.container}>
            <SequenceAnimation images={images} ref={innerRef} frameRate={55}/>
        </div>
    )
}
