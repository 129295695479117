import {useCallback, useState} from "react";
import axios from "axios";

export function useWalletAddress() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const saveWalletAddress = useCallback((address: string) => {
        setLoading(true);
        setError(null);
        setData(null);
        axios.post('https://medystacja-backend.herokuapp.com/wallet/create', {address})
            .then((res) => {
                setData(res.data)
            })
            .catch((err: any) => {
                setError(err?.response?.data?.err?.code);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return {loading, error, data, saveWalletAddress};
}
