import React, { useEffect, useState } from 'react'
import styles from './Destop.module.css'
import { Menu } from './components/menu/Menu'
import { BuyPage } from './pages/BuyPage/BuyPage'
import { LaunchPage } from './pages/LaunchPage/LaunchPage'
import { MenuItems } from '../../shared/menu.const'
import { PlayPage } from './pages/PlayPage/PlayPage'
import { StakePage } from './pages/StakePage/StakePage'
import { WaitListPage } from './pages/WaitListPage/WaitListPage'

export default function DesktopContainer() {
  const [selectedMenu, setSelectedMenu] = useState<MenuItems | undefined>()
  return (
    <div className={styles.root}>
      <Menu onChange={setSelectedMenu} />
      <LaunchPage show={selectedMenu === MenuItems.LAUNCHPAD} close={() => setSelectedMenu(undefined)} />
      <BuyPage show={selectedMenu === MenuItems.BUY} close={() => setSelectedMenu(undefined)} />
      <PlayPage show={selectedMenu === MenuItems.PLAY} close={() => setSelectedMenu(undefined)} />
      <WaitListPage show={selectedMenu === MenuItems.WAITLIST} close={() => setSelectedMenu(undefined)} />
      <StakePage show={selectedMenu === MenuItems.STAKE} close={() => setSelectedMenu(undefined)} />
      <StakePage show={selectedMenu === MenuItems.DOCS} close={() => setSelectedMenu(undefined)} />
      <StakePage show={selectedMenu === MenuItems.LEADERBOARD} close={() => setSelectedMenu(undefined)} />
      <StakePage show={selectedMenu === MenuItems.LOTTERY} close={() => setSelectedMenu(undefined)} />
    </div>
  )
}
