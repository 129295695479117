import React, {useMemo, useState} from "react";
import Campfire_Anim_000 from '@images/sequence/campfire/Campfire_Anim_001.webp';
import Campfire_Anim_001 from '@images/sequence/campfire/Campfire_Anim_002.webp';
import Campfire_Anim_002 from '@images/sequence/campfire/Campfire_Anim_003.webp';
import Campfire_Anim_003 from '@images/sequence/campfire/Campfire_Anim_004.webp';
import Campfire_Anim_004 from '@images/sequence/campfire/Campfire_Anim_005.webp';
import Campfire_Anim_005 from '@images/sequence/campfire/Campfire_Anim_006.webp';
import Campfire_Anim_006 from '@images/sequence/campfire/Campfire_Anim_007.webp';
import Campfire_Anim_007 from '@images/sequence/campfire/Campfire_Anim_008.webp';
import Campfire_Anim_008 from '@images/sequence/campfire/Campfire_Anim_009.webp';
import Campfire_Anim_009 from '@images/sequence/campfire/Campfire_Anim_010.webp';
import Campfire_Anim_010 from '@images/sequence/campfire/Campfire_Anim_011.webp';
import Campfire_Anim_011 from '@images/sequence/campfire/Campfire_Anim_012.webp';
import Campfire_Anim_012 from '@images/sequence/campfire/Campfire_Anim_013.webp';
import Campfire_Anim_013 from '@images/sequence/campfire/Campfire_Anim_014.webp';
import Campfire_Anim_014 from '@images/sequence/campfire/Campfire_Anim_015.webp';
import Campfire_Anim_015 from '@images/sequence/campfire/Campfire_Anim_016.webp';
import Campfire_Anim_016 from '@images/sequence/campfire/Campfire_Anim_017.webp';
import Campfire_Anim_017 from '@images/sequence/campfire/Campfire_Anim_018.webp';
import Campfire_Anim_018 from '@images/sequence/campfire/Campfire_Anim_019.webp';
import Campfire_Anim_019 from '@images/sequence/campfire/Campfire_Anim_020.webp';
import Campfire_Anim_020 from '@images/sequence/campfire/Campfire_Anim_021.webp';
import Campfire_Anim_021 from '@images/sequence/campfire/Campfire_Anim_022.webp';
import Campfire_Anim_022 from '@images/sequence/campfire/Campfire_Anim_023.webp';
import Campfire_Anim_023 from '@images/sequence/campfire/Campfire_Anim_024.webp';
import Campfire_Anim_024 from '@images/sequence/campfire/Campfire_Anim_025.webp';
import Campfire_Anim_025 from '@images/sequence/campfire/Campfire_Anim_026.webp';
import Campfire_Anim_026 from '@images/sequence/campfire/Campfire_Anim_027.webp';
import Campfire_Anim_027 from '@images/sequence/campfire/Campfire_Anim_028.webp';
import Campfire_Anim_028 from '@images/sequence/campfire/Campfire_Anim_029.webp';
import Campfire_Anim_029 from '@images/sequence/campfire/Campfire_Anim_030.webp';
import Campfire_Anim_030 from '@images/sequence/campfire/Campfire_Anim_031.webp';
import Campfire_Anim_031 from '@images/sequence/campfire/Campfire_Anim_032.webp';
import Campfire_Anim_032 from '@images/sequence/campfire/Campfire_Anim_033.webp';
import Campfire_Anim_033 from '@images/sequence/campfire/Campfire_Anim_034.webp';
import Campfire_Anim_034 from '@images/sequence/campfire/Campfire_Anim_035.webp';
import Campfire_Anim_035 from '@images/sequence/campfire/Campfire_Anim_036.webp';
import Campfire_Anim_036 from '@images/sequence/campfire/Campfire_Anim_037.webp';
import Campfire_Anim_037 from '@images/sequence/campfire/Campfire_Anim_038.webp';
import Campfire_Anim_038 from '@images/sequence/campfire/Campfire_Anim_039.webp';
import Campfire_Anim_039 from '@images/sequence/campfire/Campfire_Anim_040.webp';
import styles from "./Campfire.module.css";
import {SequenceAnimation} from "../../SequenceAnimation/SequenceAnimation";
import {useImagesLoaded} from "../../service/useImagesLoaded";
export const Campfire = ({id, onLoadEnd}: {id: string, onLoadEnd: () => void}) => {
    const [loading, setLoading] = useState(true);
    const images = useMemo(() => {
        return [
            Campfire_Anim_000,
            Campfire_Anim_001,
            Campfire_Anim_002,
            Campfire_Anim_003,
            Campfire_Anim_004,
            Campfire_Anim_005,
            Campfire_Anim_006,
            Campfire_Anim_007,
            Campfire_Anim_008,
            Campfire_Anim_009,
            Campfire_Anim_010,
            Campfire_Anim_011,
            Campfire_Anim_012,
            Campfire_Anim_013,
            Campfire_Anim_014,
            Campfire_Anim_015,
            Campfire_Anim_016,
            Campfire_Anim_017,
            Campfire_Anim_018,
            Campfire_Anim_019,
            Campfire_Anim_020,
            Campfire_Anim_021,
            Campfire_Anim_022,
            Campfire_Anim_023,
            Campfire_Anim_024,
            Campfire_Anim_025,
            Campfire_Anim_026,
            Campfire_Anim_027,
            Campfire_Anim_028,
            Campfire_Anim_029,
            Campfire_Anim_030,
            Campfire_Anim_031,
            Campfire_Anim_032,
            Campfire_Anim_033,
            Campfire_Anim_034,
            Campfire_Anim_035,
            Campfire_Anim_036,
            Campfire_Anim_037,
            Campfire_Anim_038,
            Campfire_Anim_039,
        ]
    }, []);
    useImagesLoaded(images, () => {
        setLoading(false);
        onLoadEnd();
    });
    return (
        <div id={id} className={styles.container}>
            {!loading && <SequenceAnimation images={images} autoPlay loop frameRate={55}/>}
        </div>
    )
}
